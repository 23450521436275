import { GroupDefinitionDto, RowDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import { isSegmentComposerRow } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { filterNotEmptyArray } from '@utils/array'

const ANIMATION_DURATION = 1000
export const ITEM_DROPPED_STATE_TIMEOUT = ANIMATION_DURATION + 500
export const RENDERER_DROPPED_STATE_TIMEOUT = 500

const deepCloneGroup = (group: GroupDefinitionDto): GroupDefinitionDto => ({
  ...group,
  rows: [...(group.rows?.filter(filterNotEmptyArray) || [])],
  subGroups: [...(group.subGroups?.filter(filterNotEmptyArray).map(deepCloneGroup) || [])],
})

/**
 * Updates a specific group in the root group with new values.
 *
 * @param targetGroup - The GroupDefinitionDto to locate.
 * @param newValues - The updated values for the target group.
 * @param rootGroup - The root GroupDefinitionDto containing all groups and rows.
 * @returns A new GroupDefinitionDto with the updated group values.
 */
export const updateGroup = (
  rootGroup: GroupDefinitionDto,
  targetGroup: GroupDefinitionDto,
  newValues: Partial<GroupDefinitionDto>
): GroupDefinitionDto => {
  const clonedRootGroup = deepCloneGroup(rootGroup)
  const updateGroupRecursively = (group: GroupDefinitionDto): GroupDefinitionDto => {
    if (group.groupId === targetGroup.groupId) {
      return { ...group, ...newValues }
    }
    if ('rows' in group) {
      return {
        ...group,
        rows: group.rows?.filter(filterNotEmptyArray),
        subGroups: group.subGroups?.filter(filterNotEmptyArray).map(updateGroupRecursively),
      }
    }
    return group
  }
  return updateGroupRecursively(clonedRootGroup)
}

export const getGroupItems = (group: GroupDefinitionDto): (RowDefinitionDto | GroupDefinitionDto)[] => {
  const { rows, subGroups } = group
  let items: (RowDefinitionDto | GroupDefinitionDto)[] = []

  if (rows) {
    items.push(...rows.filter(filterNotEmptyArray))
  }
  if (subGroups) {
    items.push(...subGroups.filter(filterNotEmptyArray))
  }

  items = items.sort((a, b) => (a.position < b.position ? -1 : 1))

  return items
}

/**
 * Moves a row from one group to another within a hierarchical structure of expression groups.
 *
 * @param {number[]} from - The path of indices representing the source location of the row to be moved.
 * @param {number[]} to - The path of indices representing the target location where the row should be inserted.
 * @param {GroupDefinitionDto} rootGroup - The root expression group that contains the entire tree structure.
 * @param {boolean} [isOverTop] - Optional flag indicating whether the row should be inserted at the top of the target group.
 *                                If true, the row is inserted above the target index.
 *                                If false or omitted, the row is inserted below the target index.
 * @returns {GroupDefinitionDto} A new root expression group with the updated structure after the row has been moved.
 * @throws {Error} If the source or target path is invalid, or if the row to be moved is not found.
 */
export const moveRow = (from: number[], to: number[], rootGroup: GroupDefinitionDto, isOverTop?: boolean): GroupDefinitionDto => {
  const updatedRoot = deepCloneGroup(rootGroup)

  // Helper function to find a group by path of indices
  const findGroup = (group: GroupDefinitionDto, path: number[]): GroupDefinitionDto => {
    return path.reduce((currentGroup, index) => {
      const items = getGroupItems(currentGroup)

      if (!items || !items[index]) {
        throw new Error('Invalid group path')
      }
      return items[index] as GroupDefinitionDto
    }, group)
  }

  const sourceGroup = findGroup(updatedRoot, from.slice(0, -1))
  const sourceIndex = from[from.length - 1]

  const targetGroup = findGroup(updatedRoot, to.slice(0, -1))
  const targetIndex = to[to.length - 1] + (isOverTop ? 0 : 1)

  // get source items in order to remove the item from the source group
  let sourceItems = getGroupItems(sourceGroup)

  // Remove item from source group
  const [movedItem] = sourceItems.splice(sourceIndex, 1)
  sourceItems = sourceItems.map((item, index) => ({ ...item, position: index + 1 }))
  sourceGroup.rows = sourceItems.filter((item) => isSegmentComposerRow(item)) as RowDefinitionDto[]
  sourceGroup.subGroups = sourceItems.filter((item) => !isSegmentComposerRow(item)) as GroupDefinitionDto[]

  if (!movedItem) {
    throw new Error('Row not found in source group')
  }

  let targetItems = getGroupItems(targetGroup)

  // Add item to target group at the specified position
  targetItems.splice(targetIndex, 0, movedItem)
  targetItems = targetItems.map((item, index) => ({ ...item, position: index + 1 }))

  const updatedTargetGroup = {
    rows: targetItems.filter((item) => isSegmentComposerRow(item)) as RowDefinitionDto[],
    subGroups: targetItems.filter((item) => !isSegmentComposerRow(item)) as GroupDefinitionDto[],
  }

  return updateGroup(updatedRoot, targetGroup, updatedTargetGroup)
}

/**
 * Retrieves the coordinates of a specific RowDefinitionDto or GroupDefinitionDto
 * within a nested GroupDefinitionDto structure.
 *
 * This function searches for the provided target (row or group) in the given
 * root RowDefinitionDto, including its subgroups, and returns the path as an
 * array of indices.
 *
 * @param targetId - The id of the RowDefinitionDto or GroupDefinitionDto to locate.
 * @param rootGroup - An GroupDefinitionDto object containing rows and subGroups.
 * @returns An array of numbers representing the hierarchical path to the target,
 *          or null if the target is not found.
 */
export const getCoordinates = (targetId: string, rootGroup: GroupDefinitionDto): number[] | null => {
  const items = getGroupItems(rootGroup)

  for (let index = 0; index < items.length; ++index) {
    const currentItem = items[index]
    // Check if the target is a row
    if ('factor' in currentItem) {
      const currentRow = currentItem as RowDefinitionDto
      if (currentRow.rowId === targetId) {
        return [index]
      }
    } else {
      const currentGroup = currentItem as GroupDefinitionDto
      if (currentGroup.groupId === targetId) {
        return [index]
      }
      // Recursively search in subgroups
      const childPath = getCoordinates(targetId, currentGroup)
      if (childPath) {
        return [index, ...childPath]
      }
    }
  }

  return null
}
