import React, { FC, ReactElement, useContext, useMemo, useState } from 'react'
import { ConnectDragSource } from 'react-dnd'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import InlineEditing from '@components/InlineEditing/InlineEditing'
import Svg, { SvgNames } from '@components/Svg'
import { SvgColor, SvgType } from '@components/Svg/Svg'
import Switch from '@components/Switch/Switch'
import Tooltip from '@components/Tooltip/Tooltip'
import TriggerButton from '@components/TriggerButton/TriggerButton'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { GroupDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import { getCoordinates } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/SegmentDefinition.utils'
import {
  combineRowsOptions,
  getAddButtonOptions,
  getGroupActionOptions,
  GroupAction,
  GroupAddButtonAction,
  renderAddButton,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { useSegmentDefinitionActions } from '@src/pages/SegmentComposer/hooks/useSegmentDefinitionActions'
import { LogicalOperator } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { a11yClick } from '@utils/a11y'

import './RowGroup.css'

export interface RowGroupProps {
  children?: ReactElement<GroupDefinitionDto> | ReactElement<GroupDefinitionDto>[]
  className?: string
  dataTest?: string
  dragSource?: ConnectDragSource
  groupDefinition: GroupDefinitionDto
  isDragged?: boolean
  isRecentlyDropped?: boolean
  onGroupDefinitionChange: (group: GroupDefinitionDto, updatedValues: Partial<GroupDefinitionDto>) => void
}

const rootClass = 'row-group'

export const RowGroup: FC<RowGroupProps> = (props: RowGroupProps) => {
  const {
    dataTest = rootClass,
    children,
    className = '',
    groupDefinition,
    isDragged = false,
    onGroupDefinitionChange,
    dragSource,
    isRecentlyDropped,
  } = props
  const { t } = useTranslation()

  const {
    update,
    values: { collapsedGroups, segmentDefinition },
  } = useContext(SegmentComposerContext)

  const { addRow, addGroup } = useSegmentDefinitionActions()

  const { name = '', logicalOperator = LogicalOperator.And } = groupDefinition
  const [deleteOpen, setDeleteOpen] = useState(false)
  const expanded = !collapsedGroups.find((groupId) => groupId === groupDefinition.groupId)

  const handleExpandCollapse = (isExpanded: boolean) => {
    if (isExpanded) {
      const index = collapsedGroups.findIndex((groupId) => groupId === groupDefinition.groupId)
      update({
        collapsedGroups: [...collapsedGroups.slice(0, index), ...collapsedGroups.slice(index + 1)],
      })
    } else {
      update({
        collapsedGroups: [...collapsedGroups, groupDefinition.groupId],
      })
    }
  }

  const handleOnActionClick = (action: GroupAction) => {
    const groupActionHandlers = {
      [GroupAction.SAVE_TEMPLATE]: () => false,
      [GroupAction.UNGROUP]: () => false,
      [GroupAction.DUPLICATE]: () => false,
      [GroupAction.COLLAPSE_EXPAND]: () => handleExpandCollapse(!expanded),
      [GroupAction.DELETE]: () => setDeleteOpen(true),
    }
    groupActionHandlers[action]()
  }

  const actionsOptions = getGroupActionOptions(t, expanded, handleOnActionClick)

  const addButtonOptions = useMemo(() => {
    const handleOnAddActionClick = (action: GroupAddButtonAction) => {
      const groupAddActionHandlers = {
        [GroupAddButtonAction.ADD_ROW]: () => addRow(groupDefinition, segmentDefinition),
        [GroupAddButtonAction.ADD_GROUP]: () => addGroup(groupDefinition, segmentDefinition),
        [GroupAddButtonAction.ADD_SAVED_GROUP]: () => false,
      }
      groupAddActionHandlers[action]()
    }
    const coordinates = getCoordinates(groupDefinition.groupId, segmentDefinition.group as GroupDefinitionDto)

    return getAddButtonOptions(t, handleOnAddActionClick, !!coordinates && coordinates.length >= 2)
  }, [groupDefinition.groupId, segmentDefinition.group, t])

  const handleNameSave = (name: string) => {
    if (!name || !!name.trim()) {
      onGroupDefinitionChange(groupDefinition, { name })
    }
  }

  return (
    <div className={className} data-test={dataTest}>
      <div
        className={classNames(rootClass, {
          [`${rootClass}__dragged`]: isDragged,
          [`${rootClass}__dropped`]: isRecentlyDropped,
        })}
      >
        <DeleteConfirmationModal
          title={t('Are you sure?')}
          body={
            <Typography
              text={t('SegmentComposer.Build.Row.Group.DeleteModal.Text', {
                groupName: name,
              })}
              tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
            />
          }
          deleteButtonText={t('Delete')}
          isOpen={deleteOpen}
          onAnswer={(answer: YesNo) => {
            if (answer === YesNo.YES) {
              // TODO: do the delete action
            }
            setDeleteOpen(false)
          }}
        />
        <div
          className={classNames(`${rootClass}__header`, {
            [`${rootClass}__header-draggable`]: dragSource,
            [`${rootClass}__header-dragged`]: isDragged,
          })}
        >
          <div className={`${rootClass}__header-left`}>
            <div className={classNames(`${rootClass}__header-left-drag`)} data-test={`${dataTest}-draggable-section`} ref={dragSource}>
              <Svg name={SvgNames.drag} type={SvgType.ICON} fill={SvgColor.LIGHT_GRAY} />
            </div>
            <InlineEditing
              className={`${rootClass}__inline-editing`}
              title={name}
              onSave={handleNameSave}
              onChange={() => undefined}
              typographyProps={{ type: TextType.BODY_TEXT_LARGE, weight: TextWeight.SEMI_MEDIUM }}
              maxWidth={700}
            />
          </div>
          <Tooltip
            trigger={
              <div role={'button'} {...a11yClick(() => handleExpandCollapse(!expanded), false)} data-test={`${dataTest}-expand-collapse`}></div>
            }
            triggerClassName={`${rootClass}__header-clickable`}
            sideOffset={10}
          >
            {t(`Click to ${expanded ? 'collapse' : 'expand'} group`)}
          </Tooltip>
          <TriggerButton
            dataTest={`${dataTest}-action-button`}
            className={`${rootClass}__action-button`}
            label={t('Actions')}
            typographyProps={{ type: TextType.BODY_TEXT }}
            options={actionsOptions}
            buttonType={ButtonType.FLOAT}
            showCaretIcon
            caretFill={SvgColor.TEXT_GRAY}
          />
        </div>
        {expanded && (
          <div className={classNames(`${rootClass}__body`, { [`${className}-body`]: className })} data-test={`${dataTest}-body`}>
            <div className={`${rootClass}__switch-container`}>
              <Typography text={t('SegmentComposer.Build.CombineGroupRows.Label')} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} />
              <Switch
                dataTest={`${dataTest}-combine-switch`}
                options={combineRowsOptions}
                selected={logicalOperator}
                onClick={(value) => {
                  onGroupDefinitionChange(groupDefinition, { logicalOperator: value as LogicalOperator })
                }}
                isToggle
              />
            </div>
            {children}
            {renderAddButton(t, addButtonOptions)}
          </div>
        )}
      </div>
    </div>
  )
}
