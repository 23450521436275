import { useContext } from 'react'

import { GroupDefinitionDto, RowDefinitionDto, SegmentDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import {
  getGroupItems,
  updateGroup,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/SegmentDefinition.utils'
import {
  getNewGroupId,
  getNewRowId,
  isSegmentComposerRow,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { FactorType, LogicalOperator, ROOT_GROUP_ID } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'

interface SegmentDefinitionActions {
  addRow: (targetGroup: GroupDefinitionDto, segmentDefinition: SegmentDefinitionDto) => void
  addGroup: (targetGroup: GroupDefinitionDto, segmentDefinition: SegmentDefinitionDto) => void
  duplicateRow: (row: RowDefinitionDto, segmentDefinition: SegmentDefinitionDto) => void
  turnRowIntoGroup: (row: RowDefinitionDto, segmentDefinition: SegmentDefinitionDto) => void
}

export const useSegmentDefinitionActions = (): SegmentDefinitionActions => {
  const {
    update,
    values: { uclFieldsOptions },
  } = useContext(SegmentComposerContext)

  const emailField = uclFieldsOptions.find((option) => option.extraOptions?.standardFieldKey === 'email')

  /**
   * Retrieves the last position of the item in the targetGroup.
   * @param targetGroup
   * @returns The last position of the item in the targetGroup.
   */
  const getLastPosition = (targetGroup: GroupDefinitionDto) => {
    const items = getGroupItems(targetGroup)
    return items[items.length - 1].position || 0
  }

  /**
   * Generates a default row definition.
   * @param lastPosition
   * @returns The default row definition.
   */
  const getDefaultRowDefinition = (lastPosition: number) => {
    return {
      rowId: getNewRowId(),
      factor: { type: FactorType.Profile, profileFactor: { fieldColumnIndex: emailField?.value } },
      position: lastPosition + 1,
    }
  }

  const getNewGroupName = (targetGroup: GroupDefinitionDto) => {
    if (targetGroup.groupId === ROOT_GROUP_ID) {
      return `Group ${targetGroup.subGroups ? targetGroup.subGroups.length + 1 : 1}`
    } else {
      return `${targetGroup.name}.${targetGroup.subGroups ? targetGroup.subGroups.length + 1 : 1}`
    }
  }

  const getOppositeLogicalOperator = ({ logicalOperator }: GroupDefinitionDto) => {
    return logicalOperator === LogicalOperator.And ? LogicalOperator.Or : LogicalOperator.And
  }

  /**
   * Generates a default group definition.
   * @param targetGroup
   * @returns The default group definition.
   */
  const getDefaultGroupDefinition = (targetGroup: GroupDefinitionDto) => {
    const lastPosition = getLastPosition(targetGroup)
    return {
      logicalOperator: getOppositeLogicalOperator(targetGroup),
      name: getNewGroupName(targetGroup),
      rows: [getDefaultRowDefinition(lastPosition)],
      subGroups: [],
      groupId: getNewGroupId(),
      position: lastPosition + 1,
    }
  }

  /**
   * Add a new default row to the last position of the targetGroup.
   *
   * @param targetGroup - The `GroupDefinitionDto` to add the new row to.
   * @param segmentDefinition - The current `SegmentDefinitionDto` being edited.
   */
  const addRow = (targetGroup: GroupDefinitionDto, segmentDefinition: SegmentDefinitionDto) => {
    const { group } = segmentDefinition
    const lastPosition = getLastPosition(targetGroup)

    const updatedGroup = {
      ...targetGroup,
      rows: [...(targetGroup.rows as RowDefinitionDto[]), getDefaultRowDefinition(lastPosition)],
    } as GroupDefinitionDto

    if (group) {
      const updatedRootGroup = updateGroup(group, targetGroup, updatedGroup)
      update({
        segmentDefinition: {
          ...segmentDefinition,
          group: updatedRootGroup,
        },
      })
    }
  }

  /**
   * Add a new default group to the last position of the targetGroup.
   * @param targetGroup
   * @param segmentDefinition
   */
  const addGroup = (targetGroup: GroupDefinitionDto, segmentDefinition: SegmentDefinitionDto) => {
    const { group } = segmentDefinition

    const updatedGroup = {
      ...targetGroup,
      subGroups: [...(targetGroup.subGroups as GroupDefinitionDto[]), getDefaultGroupDefinition(targetGroup)],
    } as GroupDefinitionDto

    if (group) {
      const updatedRootGroup = updateGroup(group, targetGroup, updatedGroup)
      update({
        segmentDefinition: {
          ...segmentDefinition,
          group: updatedRootGroup,
        },
      })
    }
  }

  /**
   * Finds the group that contains the row with the given rowId.
   * @param rowId
   * @param rootGroup
   * @returns The group that contains the row with the given rowId.
   */
  const findGroupOfRow = (rowId: string, rootGroup: GroupDefinitionDto): GroupDefinitionDto | undefined => {
    const findGroupRecursively = (group: GroupDefinitionDto) => {
      if (group.rows && group.rows.length > 0) {
        const row = group.rows.find((row) => row?.rowId === rowId)
        if (row) {
          return group
        }
      }

      let foundGroup: GroupDefinitionDto | undefined
      if (group.subGroups && group.subGroups.length > 0) {
        group.subGroups.forEach((subGroup) => {
          if (subGroup) {
            const found = findGroupRecursively(subGroup)
            if (found) {
              foundGroup = found
            }
          }
        })
      }
      return foundGroup
    }

    return findGroupRecursively(rootGroup)
  }

  /**
   * Duplicates a row and adds it to the last position of the targetGroup.
   * @param row
   * @param segmentDefinition
   */
  const duplicateRow = (row: RowDefinitionDto, segmentDefinition: SegmentDefinitionDto) => {
    const { group } = segmentDefinition
    const targetGroup = findGroupOfRow(row.rowId, group as GroupDefinitionDto)
    if (targetGroup) {
      const duplicatedRow = { ...row, rowId: getNewRowId() }

      let items = getGroupItems(targetGroup)
      const rowIndex = items.findIndex((item) => isSegmentComposerRow(item) && item.rowId === row.rowId)

      // Add item to target group next to the original row and update positions
      items.splice(rowIndex + 1, 0, duplicatedRow)
      items = items.map((item, index) => ({ ...item, position: index + 1 }))

      const updatedGroup = {
        rows: items.filter((item) => isSegmentComposerRow(item)) as RowDefinitionDto[],
        subGroups: items.filter((item) => !isSegmentComposerRow(item)) as GroupDefinitionDto[],
      }

      if (group) {
        const updatedRootGroup = updateGroup(group, targetGroup, updatedGroup)
        update({
          segmentDefinition: {
            ...segmentDefinition,
            group: updatedRootGroup,
          },
        })
      }
    }
  }

  /**
   * Turns a row into a group.
   * @param row
   * @param segmentDefinition
   */
  const turnRowIntoGroup = (row: RowDefinitionDto, segmentDefinition: SegmentDefinitionDto) => {
    const { group } = segmentDefinition
    const targetGroup = findGroupOfRow(row.rowId, group as GroupDefinitionDto)
    if (targetGroup) {
      const items = getGroupItems(targetGroup)
      const rowIndex = items.findIndex((item) => isSegmentComposerRow(item) && item.rowId === row.rowId)

      const newGroup = {
        logicalOperator: getOppositeLogicalOperator(targetGroup),
        name: getNewGroupName(targetGroup),
        rows: [row],
        subGroups: [],
        groupId: getNewGroupId(),
        position: row.position,
      }

      items.splice(rowIndex, 1, newGroup)
      const updatedGroup = {
        rows: items.filter((item) => isSegmentComposerRow(item)) as RowDefinitionDto[],
        subGroups: items.filter((item) => !isSegmentComposerRow(item)) as GroupDefinitionDto[],
      }

      if (group) {
        const updatedRootGroup = updateGroup(group, targetGroup, updatedGroup)
        update({
          segmentDefinition: {
            ...segmentDefinition,
            group: updatedRootGroup,
          },
        })
      }
    }
  }

  return {
    addRow,
    addGroup,
    duplicateRow,
    turnRowIntoGroup,
  }
}
