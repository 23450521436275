import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import FormRow from '@components/FormRow'
import Input from '@components/Input/Input'
import Label from '@components/Label'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import TimeZoneSelectV2 from '@components/TimeZoneSelectV2/TimeZoneSelectV2'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { OverviewManageProfile } from '@graphql/types/mutation-types'
import { getDynamicTimezones, getTimezoneLabel } from '@utils/timezones'

import './ProfileOverviewModal.css'

interface ProfileOverviewModalProps {
  className?: string
  dataTest?: string
  data: OverviewManageProfile
  onClose: () => void
  onAction: (data: OverviewManageProfile) => void
  isOpen: boolean
  hasExternalUserAuth?: boolean
  headerTitleKey?: string
}

const rootClass = 'profile-overview-modal'

const ProfileOverviewModal: FC<ProfileOverviewModalProps> = (props: ProfileOverviewModalProps) => {
  const { dataTest = rootClass, className = '', onAction, onClose, isOpen, data, headerTitleKey = 'Manage profile', hasExternalUserAuth } = props
  const [state, setState] = useState<OverviewManageProfile>(data)
  const [formChanged, setFormChanged] = useState<boolean>(false)

  const { t } = useTranslation()

  const onInputChange = (value: string, key: string) => {
    setFormChanged(true)
    setState({ ...state, [key]: value })
  }

  const handleAction = () => {
    onAction(state)
  }

  const handleClose = () => {
    onClose()
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      {t(headerTitleKey)}
    </ModalHeader>
  )

  const getTimezoneOptions = useMemo(() => {
    return getDynamicTimezones()
  }, [])

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody className={`${rootClass}__body`}>
        <div className={`${rootClass}__input-list`}>
          {hasExternalUserAuth && (
            <FormRow>
              <Label className={`${rootClass}__input-label`}>{t('External user ID')}</Label>
              <Input
                placeholder={t('External user ID')}
                value={state.extUserId}
                dataTest={`${dataTest}-input-external-id`}
                onChange={(event) => onInputChange(event.target.value, 'extUserId')}
              />
            </FormRow>
          )}
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('First name')}</Label>
            <Input
              placeholder={t('First name')}
              value={state.firstName}
              dataTest={`${dataTest}-input-first-name`}
              onChange={(event) => onInputChange(event.target.value, 'firstName')}
            />
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Last name')}</Label>
            <Input
              placeholder={t('Last name')}
              value={state.lastName}
              dataTest={`${dataTest}-input-last-name`}
              onChange={(event) => onInputChange(event.target.value, 'lastName')}
            />
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Job title')}</Label>
            <Input
              placeholder={t('Job title')}
              value={state.jobTitle}
              dataTest={`${dataTest}-input-job-title`}
              onChange={(event) => onInputChange(event.target.value, 'jobTitle')}
            />
          </FormRow>
          <div className="divider"></div>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Phone number')}</Label>
            <Input
              placeholder={t('Phone number')}
              value={state.phoneNumber}
              dataTest={`${dataTest}-input-phone`}
              onChange={(event) => onInputChange(event.target.value, 'phoneNumber')}
            />
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Mobile number')}</Label>
            <Input
              placeholder={t('Mobile number')}
              value={state.mobileNumber}
              dataTest={`${dataTest}-input-mobile`}
              onChange={(event) => onInputChange(event.target.value, 'mobileNumber')}
            />
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Fax number')}</Label>
            <Input
              placeholder={t('Fax number')}
              value={state.faxNumber}
              dataTest={`${dataTest}-input-fax`}
              onChange={(event) => onInputChange(event.target.value, 'faxNumber')}
            />
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Street address')}</Label>
            <Input
              placeholder={t('Street address line 1')}
              value={state.street}
              dataTest={`${dataTest}-input-street`}
              onChange={(event) => onInputChange(event.target.value, 'street')}
            />
          </FormRow>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Street address line 2')}</Label>
            <Input
              placeholder={t('Street address line 2')}
              value={state.streetLine2}
              dataTest={`${dataTest}-input-street-secondary`}
              onChange={(event) => onInputChange(event.target.value, 'streetLine2')}
            />
          </FormRow>

          <div className={`${rootClass}__input-list-two-cols`}>
            <FormRow>
              <Label className={`${rootClass}__input-label`}>{t('City')}</Label>
              <Input
                placeholder={t('City')}
                value={state.city}
                dataTest={`${dataTest}-input-city`}
                onChange={(event) => onInputChange(event.target.value, 'city')}
              />
            </FormRow>
            <FormRow>
              <Label className={`${rootClass}__input-label`}>{t('State / Province')}</Label>
              <Input
                placeholder={t('Add a state or province')}
                value={state.state}
                dataTest={`${dataTest}-input-state`}
                onChange={(event) => onInputChange(event.target.value, 'state')}
              />
            </FormRow>
          </div>
          <div className={`${rootClass}__input-list-two-cols`}>
            <FormRow>
              <Label className={`${rootClass}__input-label`}>{t('Postal code')}</Label>
              <Input
                placeholder={t('ZIP/Postal code')}
                value={state.zipCode}
                dataTest={`${dataTest}-input-zip`}
                onChange={(event) => onInputChange(event.target.value, 'zipCode')}
              />
            </FormRow>
            <FormRow>
              <Label className={`${rootClass}__input-label`}>{t('Country')}</Label>
              <Input
                placeholder={t('Country')}
                value={state.country}
                dataTest={`${dataTest}-input-country`}
                onChange={(event) => onInputChange(event.target.value, 'country')}
              />
            </FormRow>
          </div>
          <div className="divider"></div>
          <FormRow>
            <Label className={`${rootClass}__input-label`}>{t('Time zone')}</Label>
            <TimeZoneSelectV2
              label={getTimezoneLabel(state.timeZone)}
              selectProps={{
                insideModal: true,
                maxMenuHeight: 300,
                options: getTimezoneOptions,
                value: {
                  label: getTimezoneLabel(state.timeZone),
                  value: state.timeZone || '',
                },
                onChange: (e) => {
                  e && onInputChange(e.value, 'timeZone')
                },
                dataTest: `${dataTest}-input-timezone`,
              }}
            />
          </FormRow>
          <Typography
            text={t(
              'Display your local time in Act-On. Your time zone is also used for scheduled syncs, email notifications, application settings, reporting, and more.'
            )}
            className={`${rootClass}__input-list-timezone-description`}
            type={TextType.BODY_TEXT_SMALL_LIGHT}
            lineHeight={LineHeight.MEDIUM_SMALL}
          />
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button buttonType={ButtonType.TERTIARY} onClick={handleClose} dataTest={`${dataTest}-button-tertiary`}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={handleAction} disabled={!formChanged} dataTest={`${dataTest}-button-primary`}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ProfileOverviewModal
