import React, { FC, useCallback } from 'react'

import classNames from 'classnames'

import { ANALYTICS_INVISIBLE_ACTIONS } from '@src/pages/reports/Analytics/Analytics.constants'
import { AppEmbed, Page, useEmbedRef, Action } from '@thoughtspot/visual-embed-sdk/react'

import { useAnalyticsEmbedSandbox } from './useAnalyticsEmbedSandbox'

import './Analytics.css'

interface AnalyticsProps {
  hasAIAnalytics: boolean
  detailPath: string
  onDetailRender: () => void
  setRef: (ref: any) => void
  className?: string
  dataTest?: string
}

const rootClass = 'analytics'

const Analytics: FC<AnalyticsProps> = (props: AnalyticsProps) => {
  const { hasAIAnalytics, detailPath, onDetailRender, setRef, dataTest = rootClass, className = '' } = props

  const aiAction = !hasAIAnalytics ? [Action.SpotIQAnalyze] : []
  const hiddenActions = [...ANALYTICS_INVISIBLE_ACTIONS, ...aiAction]

  const embedRef = useEmbedRef<typeof AppEmbed>()

  const onRouteChange = useCallback(
    (data: any) => {
      if (data.data.currentPath !== '/home') {
        setRef(embedRef.current)
        onDetailRender()
      }
    },
    [embedRef, onDetailRender, setRef]
  )

  const { embedProps } = useAnalyticsEmbedSandbox()

  // Note: Comments intentionally left here
  // const onFilterChanged = (e: any) => {
  //   console.log(e)
  // }

  const path = detailPath.includes('pinboard') ? { path: detailPath } : {}

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <AppEmbed
        {...path}
        pageId={Page.Home}
        dataPanelV2
        additionalFlags={{ showOnboarding: false }}
        hiddenActions={hiddenActions}
        onRouteChange={onRouteChange}
        // onFilterChanged={onFilterChanged}
        fullHeight
        ref={embedRef}
        className={`${rootClass}__embed`}
        {...embedProps}
      />
    </div>
  )
}

export default Analytics
