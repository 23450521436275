import React, { FC, useCallback, useState } from 'react'

import AddressModalContainer from '@components/AddressModal/AddressModalContainer'
import Container from '@components/Container'
import { AddressResponse, CompanyPageResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import AccountTimezone from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/AccountTimezone/AccountTimezone'
import AddressesList from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/AddressesList/AddressesList'
import CompanyFooter from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/CompanyFooter/CompanyFooter'
import CompanyName from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/CompanyName/CompanyName'
import DefaultAddress from '@src/pages/Settings/OtherSettings/CustomAccountSettings/components/Company/components/DefaultAddress/DefaultAddress'

interface Props {
  companyInfo: CompanyPageResponse
  onChangeName: (name: string) => void
  onChangeTimezone: (timezone: string) => void
  refetchCompanyInfo: () => void
  setToastStatus: (value: StatusToastType) => void
  dataTest?: string
}

const rootClass = 'company'

const Company: FC<Props> = (props: Props) => {
  const { companyInfo, onChangeName, onChangeTimezone, refetchCompanyInfo, setToastStatus, dataTest = rootClass } = props

  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false)
  const [selectedAddressToEdit, setSelectedAddressToEdit] = useState<undefined | AddressResponse>(undefined)

  const onAddressEdit = useCallback((address?: AddressResponse) => {
    setAddressModalOpen(true)
    setSelectedAddressToEdit(address)
  }, [])

  return (
    <Container data-test={dataTest} className={`${rootClass}__container`}>
      <CompanyName name={companyInfo.companyName ?? ''} onChangeName={onChangeName} />
      <AccountTimezone creatorTimezone={companyInfo.creatorTimezone ?? ''} onChangeTimezone={onChangeTimezone} />
      <DefaultAddress defaultAddress={companyInfo.defaultAccountAddress ?? {}} onEdit={onAddressEdit} />
      <AddressesList addresses={companyInfo.address ?? []} onAddressEdit={onAddressEdit} />
      <CompanyFooter onAdd={onAddressEdit} />
      <AddressModalContainer
        setToastStatus={setToastStatus}
        loading={false}
        hasRequiredFields={true}
        isOpen={addressModalOpen}
        companyInfo={companyInfo}
        refetchCompanyInfo={refetchCompanyInfo}
        handleModalClose={() => setAddressModalOpen(false)}
        selectedAddressToEdit={selectedAddressToEdit}
      />
    </Container>
  )
}

export default Company
