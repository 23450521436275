import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddButton from '@components/AddButton/AddButton'
import SelectV2 from '@components/SelectV2/SelectV2'
import Switch from '@components/Switch/Switch'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
// eslint-disable-next-line no-restricted-imports
import MultiTextInput from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/MultiTextInput/MultiTextInput'

import './ConditionCard.css'

export interface Condition {
  field: string
  value: string
  filterType: string
  className?: string
}

interface ConditionCardProps {
  conditions: Condition[]
  onChange: (conditions: Condition[]) => void
  filterType: string
  className?: string
  hideMultiTextInput?: boolean
}

const conditionOptions = [
  { label: 'is', value: 'is' },
  { label: 'is not', value: 'is not' },
  { label: 'contains', value: 'contains' },
  { label: 'does not contain', value: 'does not contain' },
  { label: 'starts with', value: 'starts with' },
  { label: 'ends with', value: 'ends with' },
  { label: 'is blank', value: 'is blank' },
  { label: 'is not blank', value: 'is not blank' },
]

const rootClass = 'condition-card'

export const ConditionCard: React.FC<ConditionCardProps> = ({ hideMultiTextInput = false, conditions = [], onChange, filterType, className }) => {
  const { t } = useTranslation()
  const [localConditions, setLocalConditions] = useState<Condition[]>(conditions)
  const [toggleSwitch, setToggleSwitch] = useState<string>('Any')

  const renderCondition = (index: number) => (
    <div className="condition-card__conditions" key={index}>
      <Typography
        text={index === 0 ? t('if') : toggleSwitch === 'Any' ? t('or') : t('and')}
        weight={TextWeight.MEDIUM}
        type={TextType.BODY_TEXT_LIGHT}
      />
      <div className="condition-card__conditions-fields">
        <SelectV2
          options={[{ label: 'Option 0', value: 'option-0' }]}
          onChange={() => {}}
          value={null}
          placeholder={t('Condition')}
          dataTest={`${rootClass}-select-field-${index}`}
        />
        <SelectV2 options={conditionOptions} onChange={() => {}} value={null} placeholder={t('is')} dataTest={`${rootClass}-select-field-${index}`} />
        {!hideMultiTextInput && (
          <MultiTextInput onChange={() => {}} dataTest={`${rootClass}-input-field-${index}`} selectedOptions={[]} onCreate={() => {}} />
        )}
      </div>
      <AddButton disabled={false} isDelete={true} onClick={() => removeCondition(index)} dataTest={`${rootClass}-button-delete-condition`} />
    </div>
  )

  const addCondition = () => {
    if (localConditions.length < 2) {
      const newCondition = { field: '', operator: 'equals', value: '', filterType }
      const updatedConditions = [...localConditions, newCondition]
      setLocalConditions(updatedConditions)
      onChange(updatedConditions)
    }
  }

  const removeCondition = (index: number) => {
    const updatedConditions = localConditions.filter((_, i) => i !== index)
    setLocalConditions(updatedConditions)
    onChange(updatedConditions)
  }

  return (
    <div className={`${rootClass} ${className}`}>
      <div className="condition-card__header">
        <Typography className={`${rootClass}__filter-type`} text={filterType} weight={TextWeight.MEDIUM} type={TextType.BODY_TEXT_LIGHT} />
        <Switch
          selected={toggleSwitch}
          options={[{ label: 'Any' }, { label: 'All' }]}
          onClick={(value) => {
            setToggleSwitch(value)
          }}
          isToggle={true}
        />
        <Typography
          className={`${rootClass}__filter-type`}
          text={t('of the following conditions:')}
          weight={TextWeight.MEDIUM}
          type={TextType.BODY_TEXT_LIGHT}
        />
      </div>

      {localConditions.map((_, index) => renderCondition(index))}

      <div className="condition-card__footer">
        <AddButton
          label={t(`Add filter`)}
          disabled={localConditions.length >= 2}
          onClick={addCondition}
          dataTest={`${rootClass}-button-add-condition`}
        />
      </div>
    </div>
  )
}

export default ConditionCard
