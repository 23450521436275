import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { LandingPageStatus } from '@complex/ListingPage/Utils/ListingPage.constants'
import Button, { ButtonType } from '@components/Button/Button'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import InlineEditing from '@components/InlineEditing/InlineEditing'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import SplitButton from '@components/SplitButton/SplitButton'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextWeight } from '@components/Typography/Typography'
import Logo from '@src/components/Navigation/components/Logo'
import AutoSaveIndicator from '@src/pages/EmailComposer/components/EmailComposerHeader/components/AutoSaveIndicator/AutoSaveIndicator'
import {
  webinarMessageCategories,
  webinarRegistrationMessageStatuses,
} from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { isCustomerCareLogin } from '@utils/cookie'
import { ensureFirstLetterIsCapitalized, replaceSpaces, titleCase } from '@utils/strings'
import { THEME } from '@utils/theme'

import { getMessageConfigurationType } from '../../utils/EmailComposer.utils'
import { HeaderMessageTypeMapping, HeaderWebinarMessageTypeMapping } from '../../utils/MessageType.settings'

import './EmailComposerHeader.css'

export interface EmailComposerHeaderProps {
  dataTest?: string
}

const rootClass = 'email-composer-header'

const webinarTypeMessages = ['WEBINAR_SINGLE', 'WEBEX_MULTI', 'GOTOWEBINAR_MULTI', 'WEBINAR_MESSAGE']

export const EmailComposerHeader: FC<EmailComposerHeaderProps> = ({ dataTest = rootClass }) => {
  const {
    values: {
      loading,
      isStory,
      lastSavedTitle,
      haveUnsavedChanges,
      cancelHeaderTitleEdit,
      messageConfiguration: { showOnlyDiscardButton, hideAutoSave, saveButton, settings, messageType: configMessageType },
      message: { title, messageType, abTestVariation, webinarConfig },
      landingPage: { isLandingPageEnabled, isLandingPage },
    },
    api: { onSave, onClose, onSaveAndClose, onSaveAsCopy, onSaveAsDraft, onSaveAsTemplate, onDiscardAndClose, update },
  } = useComposerContext()

  const { separateSaveAndClose, saveAndClose, separateDiscardAndPublish, saveAsCopy, saveAsTemplate, copyToDraft } = saveButton ?? {}

  const { t } = useTranslation()

  const { enableEmailDraftsReact, isCustomLogo, appLogo, isMicrosoftStartPage, userSettings, customLogos } = useAccountSettings()

  const { isEmailABTest, isEmailTemplate, isEmailCRM } = detectEmailType(messageType)
  const { isEmailBlankMessage, isEmailResend, isEmailDraft, isOptInEmail } = detectEmailType(configMessageType)

  const headerStatusLoading = loading && !isStory && !isEmailCRM && !isEmailBlankMessage && !isEmailResend

  const saveOptionsLength = (saveButton && Object.keys(saveButton).length) ?? 0
  const isSeparateSaveAndClose =
    separateSaveAndClose ||
    (isCustomerCareLogin() && !isOptInEmail && !isEmailResend) ||
    saveButton?.autoSaveWithSeparateSaveAndClose ||
    separateDiscardAndPublish

  const options = useMemo(
    () => [
      ...(saveAndClose
        ? [
            {
              key: 'save',
              title: t('Save and close'),
              onClick: () => onSaveAndClose(),
            },
          ]
        : []),
      ...(saveAsCopy ? [{ key: 'copy', title: t('Save as copy'), onClick: () => onSaveAsCopy() }] : []),
      ...(saveAsTemplate ? [{ key: 'template', title: t('Save as template'), onClick: () => onSaveAsTemplate() }] : []),
      ...(copyToDraft ? [{ key: 'draft', title: t('Save as draft'), onClick: () => onSaveAsDraft() }] : []),
      ...(showOnlyDiscardButton ? [{ key: 'close-and-discard', title: t('Close and discard'), onClick: () => onDiscardAndClose() }] : []),
    ],
    [
      saveAndClose,
      saveAsCopy,
      saveAsTemplate,
      copyToDraft,
      showOnlyDiscardButton,
      t,
      onSaveAndClose,
      onSaveAsCopy,
      onSaveAsTemplate,
      onSaveAsDraft,
      onDiscardAndClose,
    ]
  )

  const cancelTitleChanges = useCallback(() => {
    update({ cancelHeaderTitleEdit: false, message: { title: lastSavedTitle } })
  }, [lastSavedTitle, update])

  const handleChange = useCallback(
    (title: string) => {
      if (!title || !!title.trim()) {
        update({ cancelHeaderTitleEdit: false, message: { title } })
      }
    },
    [update]
  )

  const handleSave = useCallback(
    (title: string) => update({ lastSavedTitle: replaceSpaces(title), message: { title: replaceSpaces(title) } }),
    [update]
  )

  let logoLink
  switch (true) {
    case isLandingPage:
      logoLink = `content/landingPages`
      break
    case isEmailTemplate:
      logoLink = `content/emailTemplates`
      break
    case enableEmailDraftsReact:
      logoLink = `outbound/drafts`
      break
    default:
      logoLink = `classic/draftLists`
      break
  }

  const isDisabled = (showOnlyDiscardButton || saveButton?.separateDiscardAndPublish) && haveUnsavedChanges === undefined

  const isWebinarRegistered = useMemo(() => {
    return webinarRegistrationMessageStatuses.includes(webinarConfig?.messageCategory ?? '')
  }, [webinarConfig?.messageCategory])

  const isWebinarMessageCategory = useMemo(() => {
    return webinarMessageCategories.includes(webinarConfig?.messageCategory ?? '')
  }, [webinarConfig?.messageCategory])

  const headerMessage = useMemo(() => {
    const messageTypeLabel = t(HeaderMessageTypeMapping[messageType])
    const messageConfigurationType: string = getMessageConfigurationType().toUpperCase() || messageType
    const messageWebinarCategoryType: string = webinarConfig?.messageCategory || ''
    const isWebinarType = webinarTypeMessages.includes(messageConfigurationType)

    switch (true) {
      case isOptInEmail:
      case isEmailBlankMessage:
      case isEmailDraft:
      case isEmailTemplate: {
        return t(HeaderMessageTypeMapping[messageConfigurationType])
      }

      case isWebinarRegistered && isWebinarType:
        return (
          <Typography
            text={t('EmailComposer.Webinar.Header', { category: messageWebinarCategoryType, context: 'registration' })}
            tagProps={{ bold: { weight: TextWeight.MEDIUM, inline: true } }}
          />
        )
      case isWebinarMessageCategory && isWebinarType:
        return (
          <Typography
            text={t('EmailComposer.Webinar.Header', {
              category: ensureFirstLetterIsCapitalized(HeaderWebinarMessageTypeMapping[messageWebinarCategoryType.toUpperCase()]),
            })}
            tagProps={{ bold: { weight: TextWeight.MEDIUM, inline: true } }}
          />
        )
      default:
        return messageTypeLabel
    }
  }, [
    t,
    isEmailBlankMessage,
    isEmailDraft,
    isEmailTemplate,
    isOptInEmail,
    isWebinarMessageCategory,
    isWebinarRegistered,
    messageType,
    webinarConfig?.messageCategory,
  ])

  const computedTitle = useMemo(() => {
    if (isEmailBlankMessage) {
      return t('Blank.Email.Setup.Title')
    } else {
      return title
    }
  }, [isEmailBlankMessage, t, title])

  return (
    <div className={isCustomerCareLogin() ? `${rootClass}__customer-care` : `${rootClass} ${rootClass}__remove-padding`}>
      {isCustomerCareLogin() && (
        <InfoStatus
          status={InfoStatusTypes.Warning}
          message={<Typography text={t('EmailComposer.Header.CustomerCare.Banner')} tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }} />}
          svgName={SvgNames.cautionError}
          className={`${rootClass}__customer-care`}
        />
      )}
      <div className={rootClass}>
        <div className={`${rootClass}__nav flex-align-center`}>
          <Logo
            expanded={false}
            theme={THEME.LIGHT}
            isCustomLogo={isCustomLogo}
            appLogo={appLogo}
            isMicrosoftStartPage={isMicrosoftStartPage}
            userDesktop={userSettings.userDesktop}
            customLogos={customLogos}
            pageSpecificLink={logoLink}
            isNonRedirectingLogo={isEmailCRM}
          />
          <InlineEditing
            title={computedTitle}
            triggerCancel={cancelHeaderTitleEdit}
            onChange={handleChange}
            onCancel={cancelTitleChanges}
            onSave={handleSave}
            highlightOnFocus
            className="flex-align-center"
            maxWidth={700}
            minWidth={128}
            maxLength={400}
            disabled={settings.hideMessageTitle || settings.disableEditings}
            dataTest={dataTest}
          >
            {!isLandingPage ? (
              <div className={`${rootClass}__status-wrapper`}>
                {headerStatusLoading ? (
                  <Loader className={`${rootClass}__status-loader`} loaderType={LoaderTypes.row} />
                ) : isEmailABTest ? (
                  <div className={`${rootClass}__status`}>
                    <Typography
                      text={t('EmailComposer.Header.Type.ABTest', { variation: abTestVariation })}
                      tagProps={{ bold: { weight: TextWeight.MEDIUM, inline: true } }}
                    />
                  </div>
                ) : (
                  <Typography
                    className={classNames(`${rootClass}__status`, { [`${rootClass}__status-empty`]: !HeaderMessageTypeMapping[messageType] })}
                    dataTest={`${rootClass}-status`}
                    text={headerMessage}
                  />
                )}
              </div>
            ) : (
              <div className={`${rootClass}__landing-page-status-wrapper`}>
                <div className={`${rootClass}__landing-page-status`}>
                  <Svg className="push-left-x2" name={isLandingPageEnabled ? SvgNames.circleGreen : SvgNames.circle} type={SvgType.SMALLER_ICON} />
                  <Typography
                    text={t(titleCase(isLandingPageEnabled ? LandingPageStatus.ENABLED : LandingPageStatus.DISABLED))}
                    weight={TextWeight.MEDIUM}
                    lineHeight={LineHeight.TINY}
                    tagProps={{ bold: { weight: TextWeight.MEDIUM, inline: true } }}
                  />
                </div>
              </div>
            )}
          </InlineEditing>
        </div>
        <div className={`${rootClass}__right flex-align-center`}>
          {!hideAutoSave && !isEmailCRM && !isEmailResend && <AutoSaveIndicator />}

          {isSeparateSaveAndClose && (
            <div className={`${rootClass}__save-close`} data-test={`${rootClass}-separate-header-buttons`}>
              <Tooltip
                hide={!isDisabled}
                position="bottom"
                trigger={
                  <Button
                    buttonType={saveButton?.separateDiscardAndPublish && haveUnsavedChanges === undefined ? ButtonType.SECONDARY : ButtonType.WHITE}
                    onClick={onClose}
                    dataTest={`${dataTest}-close-only`}
                    disabled={isDisabled}
                  >
                    {t(saveButton?.separateDiscardAndPublish ? 'Discard' : 'Close')}
                  </Button>
                }
              >
                {t('Composer.Discard.Disabled.Tooltip')}
              </Tooltip>
              <Tooltip
                hide={!isDisabled}
                position="bottom"
                trigger={
                  <Button
                    buttonType={ButtonType.PRIMARY}
                    onClick={() => onSave(saveButton?.pureSave)}
                    dataTest={`${dataTest}-save-only`}
                    disabled={isDisabled}
                  >
                    {t(saveButton?.separateDiscardAndPublish ? 'Publish changes' : 'Save')}
                  </Button>
                }
              >
                {t('Composer.Publish.Disabled.Tooltip')}
              </Tooltip>
            </div>
          )}
          {saveOptionsLength > 0 && !isSeparateSaveAndClose && <SplitButton options={options} dataTest={`${dataTest}-split`} />}
        </div>
      </div>
    </div>
  )
}
