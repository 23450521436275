import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { LandingPageStatus } from '@complex/ListingPage/Utils/ListingPage.constants'
import LandingPagesPickerModal from '@components/AssetPickers/LandingPagesPickerModal/LandingPagesPickerModal'
import AssetSelect from '@components/AssetSelect/AssetSelect'
import Button, { ButtonType } from '@components/Button'
import { ButtonSize, ButtonWeight } from '@components/Button/Button'
import Container from '@components/Container'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import InputV2 from '@components/InputV2/InputV2'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import { LinkTextButton, LinkTextButtonColor, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgType } from '@components/Svg'
import { SvgNames } from '@components/Svg/index'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Toggle from '@components/Toggle'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import getCustomAccountSettingsPage from '@graphql/queries/getCustomAccountSettingsPage'
import getLandingPageQuery from '@graphql/queries/getLandingPage'
import {
  GetCustomAccountSettingsPageQuery,
  GetCustomAccountSettingsPageQueryVariables,
  GetLandingPageQuery,
  GetLandingPageQueryVariables,
} from '@graphql/types/query-types'
import { useLandingPageComposerRequests } from '@src/pages/LandingPageComposer/GraphQL/LandingPageComposerRequests.graphQL'
import { landingPageConfirmationModal } from '@src/pages/LandingPageComposer/utils/LandingPageComposer.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { LandingPageRedirectUrl } from '@utils/composer/context/LandingPageComposer.context'
import { detailedDateFormat } from '@utils/date/dateUtils'
import { URLRegExpWithoutHTTP } from '@utils/formUtils'

import { formatTimestamp, getRedirectUrlOptions } from './utils/PageStatusPane.utils'
import { SettingsFormChangeHandler } from '../../utils/LandingPageComposerSettings.utils'

import './PageStatusPane.css'

export interface PageStatusPaneProps {
  className?: string
  dataTest?: string
  onFieldChange: SettingsFormChangeHandler
}

const rootClass = 'page-status-pane'

const GLOBAL_REDIRECT_URL_ERROR_LINK =
  'https://connect.act-on.com/hc/en-us/articles/360023944253-Unpublishing-a-Landing-Page#h_01HAW4DXHZ4RNK9REAMPDA02AZ'

const PageStatusPane: FC<PageStatusPaneProps> = (props) => {
  const { className, onFieldChange, dataTest = rootClass } = props

  const client = useApolloClient()
  const { t } = useTranslation()

  const [open, setOpen] = useState<boolean>(false)
  const [urlError, setUrlError] = useState<boolean>(false)
  const [urlHttpsError, setUrlHttpsError] = useState<boolean>(false)
  const [hasRedirectURLBeenOpened, setHasRedirectURLBeenOpened] = useState<boolean>(false)

  const {
    values: {
      haveUnsavedChanges,
      message: { title, id },
      landingPage: {
        isLandingPageEnabled,
        lastStatusEdited,
        landingPageUrlType,
        landingPageIndividualUrl,
        landingPageId,
        isLandingPageStatusChanged,
        globalRedirectUrl,
        showErrors,
        isLandingPagePublished,
        hasUnpublishedChanges,
        lastPublishedTime,
      },
    },
    api: { update, onSave, updateModal },
  } = useComposerContext()
  const { getLastPublishedMessage } = useLandingPageComposerRequests()

  const [getLandingPage, { data: landingPageData }] = useLazyQuery<GetLandingPageQuery, GetLandingPageQueryVariables>(getLandingPageQuery, {
    client,
    fetchPolicy: 'no-cache',
    variables: { id: landingPageId || '' },
  })

  const { newLPComposerCreateBlank } = useAccountSettings()

  const { data: unpublishedUrlData } = useQuery<GetCustomAccountSettingsPageQuery, GetCustomAccountSettingsPageQueryVariables>(
    getCustomAccountSettingsPage,
    {
      client,
      fetchPolicy: 'network-only',
    }
  )

  const { checkIsUrlValid } = useLandingPageComposerRequests()

  const redirectUrlOptions = getRedirectUrlOptions(t)
  const statusInfoContext = isLandingPageEnabled ? LandingPageStatus.ENABLED : LandingPageStatus.DISABLED

  const statusInfoContextPublishFlow = newLPComposerCreateBlank ? (isLandingPagePublished ? 'live' : 'draft') : undefined

  const isPublished = newLPComposerCreateBlank && isLandingPagePublished

  const isToggleEnabled =
    isLandingPageEnabled &&
    ((landingPageUrlType === LandingPageRedirectUrl.GLOBAL && !globalRedirectUrl) ||
      (landingPageUrlType === LandingPageRedirectUrl.INDIVIDUAL && !landingPageIndividualUrl) ||
      (landingPageUrlType === LandingPageRedirectUrl.LANDING_PAGE && !landingPageId))

  const checkUrlValidity = (value: string) => {
    if (!URLRegExpWithoutHTTP.test(value)) {
      if (!urlError) {
        setUrlHttpsError(true)
      }

      return { errorMessageKey: 'invalidUrl' }
    } else {
      setUrlError(false)
      setUrlHttpsError(false)
    }
  }

  const checkValidityOnBlur = (value: string) => {
    checkIsUrlValid({ url: value }).then(({ data }) => {
      if (data?.isValidUrl) {
        checkUrlValidity(value)
      } else {
        if (!urlHttpsError) {
          setUrlError(true)
        }

        return { errorMessageKey: 'invalidUrl' }
      }
    })
  }

  useEffect(() => {
    if (landingPageIndividualUrl) {
      checkValidityOnBlur(landingPageIndividualUrl)
    }
  }, [])

  useEffect(() => {
    update({ landingPage: { hasLandingPageIndividualUrlError: urlError || urlHttpsError } })
  }, [urlError, urlHttpsError])

  useEffect(() => {
    if (landingPageId) {
      getLandingPage()
    }
  }, [getLandingPage, landingPageId])

  useEffect(() => {
    isToggleEnabled && onFieldChange('isLandingPageEnabled', true)
  }, [isToggleEnabled, onFieldChange])

  useEffect(() => {
    update({ landingPage: { globalRedirectUrl: unpublishedUrlData?.getCustomAccountSettingsPage.unpublishedLandingPage.url } })
  }, [unpublishedUrlData])

  const statusChangePending = haveUnsavedChanges && isLandingPageStatusChanged

  const renderModal = () => {
    return (
      <LandingPagesPickerModal
        isOpen={open}
        excludeItemIds={newLPComposerCreateBlank && id ? [id] : []}
        preSelectedRowIds={landingPageData ? [landingPageData.getLandingPage.id] : []}
        onSubmit={(item) => {
          onFieldChange('landingPageId', item[0].externalId)
          setOpen(false)
        }}
        onClose={() => setOpen(false)}
        isSingleSelect
      />
    )
  }

  const errorMessages = {
    invalidUrl: t('LandingPageComposer.Settings.PageStatus.Individual.Error.Tooltip'),
  }

  const selectLandingPageError =
    landingPageUrlType === LandingPageRedirectUrl.LANDING_PAGE && ((!landingPageId && hasRedirectURLBeenOpened) || (!landingPageId && showErrors))
      ? t('LandingPageComposer.Settings.PageStatus.SelectLandingPage.Required')
      : undefined

  return (
    <Container>
      <div className={classNames(rootClass, className)}>
        <div className={`${rootClass}__title`}>
          <Typography text={t('Page Status')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />
        </div>
        <div
          className={classNames(`${rootClass}__status`, {
            [`${rootClass}__status-enabled`]: isLandingPageEnabled,
            [`${rootClass}__status-small-margin`]: newLPComposerCreateBlank && lastPublishedTime,
            [`${rootClass}__status-teal-bg`]: isLandingPagePublished,
          })}
        >
          <div className={classNames({ [`${rootClass}__status-info`]: !newLPComposerCreateBlank })} data-test={`${dataTest}-status-info`}>
            {statusChangePending && (
              <Typography
                type={TextType.BODY_TEXT_LARGE}
                lineHeight={LineHeight.MEDIUM_LARGE}
                text={t(`LandingPageComposer.Settings.PageStatus.StatusPending`, { context: statusInfoContext })}
                tagProps={{ bold: { weight: TextWeight.BOLD } }}
                inline
              />
            )}
            {!statusChangePending && (
              <div className={classNames({ [`${rootClass}__status-pane`]: newLPComposerCreateBlank })}>
                <div
                  className={classNames('', {
                    [`${rootClass}__status-pane-status`]: newLPComposerCreateBlank,
                  })}
                >
                  {newLPComposerCreateBlank && (
                    <Svg
                      className={`${rootClass}__status-pane-status__svg`}
                      name={isLandingPagePublished ? SvgNames.circleGreen : SvgNames.circle}
                      type={SvgType.MEDIUM}
                    />
                  )}
                  <Typography
                    dataTest={dataTest}
                    type={TextType.BODY_TEXT_LARGE}
                    lineHeight={LineHeight.MEDIUM_LARGE}
                    text={t(newLPComposerCreateBlank ? 'LandingPage.StatusPane' : 'LandingPageComposer.Settings.PageStatus.Status', {
                      context: newLPComposerCreateBlank ? statusInfoContextPublishFlow : statusInfoContext,
                    })}
                    tagProps={{ bold: { weight: TextWeight.BOLD } }}
                    inline
                  />
                </div>

                {!newLPComposerCreateBlank && (
                  <Typography
                    text={t('LandingPageComposer.Settings.PageStatus.Status.ChangeTime', {
                      context: statusInfoContext,
                      date: formatTimestamp(lastStatusEdited ?? Date.now()),
                    })}
                    tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
                    inline
                  />
                )}
              </div>
            )}
          </div>

          {isPublished && (
            <Button
              dataTest={`${dataTest}-unpublish-button`}
              buttonType={ButtonType.WHITE}
              onClick={() => {
                landingPageConfirmationModal({
                  t,
                  title: 'LandingPage.Unpublish.Confirmation.title',
                  bodyText: 'LandingPage.Unpublish.Confirmation.bodyText',
                  updateModal,
                  onConfirm: () => {
                    onSave(true, false, false)?.then((status) => {
                      if (status === undefined) return

                      if (status) {
                        update({ landingPage: { isLandingPagePublished: false, lastPublishedTime: Date.now() } })

                        updateModal('statusToast', {
                          status: Status.SUCCESS,
                          message: (
                            <Typography
                              text={t('LandingPag.HasBeen.Unpublished', { name: title })}
                              tagProps={{ b: { weight: TextWeight.BOLD, className: undefined } }}
                              inline
                            />
                          ),
                        })
                      } else {
                        updateModal('statusToast', {
                          status: Status.FAIL,
                          title: t('LandingPageComposer.Unable.Publish', {
                            publishType: 'unpublish',
                            name: isLandingPagePublished ? 'changes' : 'page',
                          }),
                          message: t('LandingPageComposer.Unable.Publish.desc', {
                            publishType: 'unpublish',
                            name: isLandingPagePublished ? 'changes' : 'page',
                          }),
                        })

                        update({
                          landingPage: {
                            isLandingPagePublished: isLandingPagePublished ?? false,
                            hasUnpublishedChanges: hasUnpublishedChanges ?? false,
                            lastPublishedTime: lastPublishedTime ?? undefined,
                          },
                        })
                      }
                    })
                  },
                })
              }}
              buttonSize={ButtonSize.MEDIUM}
              weight={ButtonWeight.MEDIUM}
            >
              {t('LandingPage.Unpublish')}
            </Button>
          )}

          {!newLPComposerCreateBlank && (
            <Toggle
              large
              isOn={!!isLandingPageEnabled}
              onToggle={(value) => {
                update({
                  landingPage: { lastStatusEdited: Date.now(), isLandingPageEnabled: value, isLandingPageStatusChanged: !isLandingPageStatusChanged },
                })
              }}
              noLeftMargin
              noRightMargin
              dataTest={`${dataTest}-status-toggle`}
            />
          )}
        </div>
        {newLPComposerCreateBlank && lastPublishedTime && (
          <div className={`${rootClass}__last-published-time`}>
            <TextWithTooltipOnEllip
              typographyProps={{
                dataTest: `${dataTest}-last-published-time`,
                className: classNames(`${rootClass}__last-published-text`),
                text: t(isLandingPagePublished ? 'Last published {{timestamp}}' : 'Last unpublished {{timestamp}}', {
                  timestamp: detailedDateFormat(lastPublishedTime),
                }),
                lineHeight: LineHeight.MEDIUM_SMALL,
              }}
            />
            {!isLandingPagePublished && hasUnpublishedChanges && (
              <div className={`${rootClass}__restore-button`}>
                <LinkTextButton
                  hideIcons
                  text={t('LandingPage.Restore')}
                  size={LinkTextButtonSize.SMALL}
                  weight={TextWeight.MEDIUM}
                  color={LinkTextButtonColor.TEAL}
                  onClick={() => {
                    landingPageConfirmationModal({
                      t,
                      title: 'LandingPage.Publish.PreviousVersion',
                      bodyText: 'LandingPage.Publish.PreviousVersion.desc',
                      updateModal,
                      onConfirm: () => {
                        getLastPublishedMessage?.(id).then((lastPublishedMessageData) => {
                          onSave(true, false, true, lastPublishedMessageData)?.then((status) => {
                            if (status === undefined) return

                            update({
                              landingPage: {
                                isLandingPagePublished: true,
                                lastPublishedTime: Date.now(),
                              },
                            })

                            if (status) {
                              updateModal('statusToast', {
                                status: Status.SUCCESS,
                                message: (
                                  <Typography
                                    text={t('LandingPag.HasBeen.Published', { name: title })}
                                    tagProps={{ b: { weight: TextWeight.BOLD, className: undefined } }}
                                    inline
                                  />
                                ),
                              })
                            } else {
                              updateModal('statusToast', {
                                status: Status.FAIL,
                                title: t('LandingPageComposer.Unable.Publish', {
                                  publishType: 'publish',
                                  name: isLandingPagePublished ? 'changes' : 'page',
                                }),
                                message: t('LandingPageComposer.Unable.Publish.desc', {
                                  publishType: 'publishing',
                                  name: isLandingPagePublished ? 'changes' : 'page',
                                }),
                              })
                              update({
                                landingPage: {
                                  isLandingPagePublished: isLandingPagePublished ?? false,
                                  hasUnpublishedChanges: hasUnpublishedChanges ?? false,
                                  lastPublishedTime: lastPublishedTime ?? undefined,
                                },
                              })
                            }
                          })
                        })
                      },
                    })
                  }}
                />
                <InfoTooltip align="center" iconSize={InfoTooltipIconSize.SMALL}>
                  <Typography text={t('LandingPage.Restore.Tootlip')} type={TextType.BODY_TEXT_WHITE} tagProps={{ medium: { inline: true } }} />
                </InfoTooltip>
              </div>
            )}
          </div>
        )}
        <div>
          <div className="push-up flex-align-center">
            <LabelWithSvgTooltip
              label={newLPComposerCreateBlank ? t(`LandingPage.PageRedirect.URL`) : t(`LandingPageComposer.Settings.PageStatus.URL`)}
              tooltipContent={
                newLPComposerCreateBlank ? t(`LandingPage.PageRedirect.URL.Tooltip`) : t(`LandingPageComposer.Settings.PageStatus.URL.Tooltip`)
              }
              align="center"
              svgType={SvgType.LARGER_ICON}
            />
          </div>
          <SelectV2
            onChange={(option) => {
              onFieldChange('landingPageUrlType', option?.value)
              onFieldChange('landingPageId', undefined)
            }}
            options={redirectUrlOptions}
            defaultValue={redirectUrlOptions[0]}
            value={redirectUrlOptions.find((item: SelectV2SingleOption) => item.value === landingPageUrlType)}
            isClearable={false}
            dataTest={`${dataTest}-url-options`}
            error={showErrors && !globalRedirectUrl && !isLandingPageEnabled && landingPageUrlType === LandingPageRedirectUrl.GLOBAL}
            errorMessage={'LandingPageComposer.Settings.PageStatus.URL.Error'}
            errorLink={GLOBAL_REDIRECT_URL_ERROR_LINK}
          />
          {landingPageUrlType !== LandingPageRedirectUrl.GLOBAL && (
            <div className={`${rootClass}__redirect-input-container`}>
              <div className={`${rootClass}__redirect-input-divider`}></div>
              {landingPageUrlType === LandingPageRedirectUrl.INDIVIDUAL ? (
                <InputV2
                  fullWidth
                  placeholder="https://example.com"
                  value={landingPageIndividualUrl}
                  statusProps={{
                    validityFunctions: [checkUrlValidity],
                    tooltipErrorMessages: errorMessages,
                  }}
                  inputInfo={{
                    enabled: urlHttpsError || urlError || (!landingPageIndividualUrl && !!showErrors),
                    errorText: urlHttpsError ? t('LandingPages.UrlModal.ErrorMsg') : t('LandingPages.UrlModal.ErrorMsg_other'),
                  }}
                  onChange={(e) => onFieldChange('landingPageIndividualUrl', e.target.value)}
                  onBlur={() => checkValidityOnBlur(landingPageIndividualUrl ?? '')}
                  onFocus={() => checkValidityOnBlur(landingPageIndividualUrl ?? '')}
                  dataTest={`${dataTest}-input-with-status`}
                  error={!landingPageIndividualUrl && !!showErrors}
                />
              ) : landingPageUrlType === LandingPageRedirectUrl.LANDING_PAGE ? (
                <div className={`${rootClass}__select-landing-page ellip`}>
                  {open && renderModal()}
                  <AssetSelect
                    replaceText={landingPageId ? 'Replace' : 'Select'}
                    selectedAssets={landingPageData?.getLandingPage.name}
                    error={!!selectLandingPageError}
                    helperText={selectLandingPageError}
                    svgIcon={SvgNames.page}
                    placeholder={t('AssetPicker.LandingPages.Header.Title', { context: 'one' })}
                    onClick={() => {
                      setOpen(true)
                      if (!hasRedirectURLBeenOpened) {
                        setHasRedirectURLBeenOpened(true)
                      }
                    }}
                    className={classNames(`${rootClass}__select-landing-page-selector`, {
                      [`${rootClass}__select-landing-page-selector-error`]: !landingPageId && hasRedirectURLBeenOpened,
                    })}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default PageStatusPane
