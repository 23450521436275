type AssetPreviewMode = 'image' | 'pdf' | 'office' | 'google' | 'sync' | 'unsupported' | 'async'

const assetPreviewModes: Record<AssetPreviewMode, string[]> = {
  image: ['jpeg', 'jpg', 'gif', 'png', 'svg'],
  pdf: ['pdf'],
  office: ['doc', 'docx', 'ppt', 'pptx', 'pps', 'ppsx', 'xls', 'xlsx'],
  google: ['ai', 'csv', 'eps', 'ps', 'rtf'],
  sync: ['html', 'htm', 'css', 'js', 'txt'],
  unsupported: [],
  async: [],
}

export const getAssetPreviewMode = (url?: string, checkIsPreviewEmpty?: boolean): AssetPreviewMode => {
  if (checkIsPreviewEmpty !== undefined) {
    return checkIsPreviewEmpty ? 'async' : 'sync'
  }
  const fileExtension = url?.split('.').pop()?.toLowerCase()
  const mode = Object.keys(assetPreviewModes).filter((mode) => assetPreviewModes[mode as AssetPreviewMode].includes(fileExtension ?? ''))
  return (mode[0] as AssetPreviewMode) ?? 'unsupported'
}

export const getAssetPreviewUrl = (previewMode: AssetPreviewMode, assetSrc?: string, isEmbedded = true) => {
  return previewMode === 'office'
    ? getOfficePreviewUrl(assetSrc, isEmbedded)
    : previewMode === 'google'
    ? getGooglePreviewUrl(assetSrc, isEmbedded)
    : assetSrc
}

export const getGooglePreviewUrl = (url?: string, isEmbedded = true) => {
  return `https://docs.google.com/gview?${isEmbedded ? 'embedded=true&' : ''}url=${url}`
}

export const getOfficePreviewUrl = (url?: string, isEmbedded = true) => {
  return `https://view.officeapps.live.com/op/${isEmbedded ? 'embed' : 'view'}.aspx?src=${url}`
}

export const disableClicks = (htmlString: string): string => {
  const parser = new DOMParser()

  const doc = parser.parseFromString(htmlString, 'text/html')

  const body = doc.querySelector('body')

  if (body) {
    body.style.pointerEvents = 'none'
  }

  return doc.documentElement?.outerHTML
}

export enum PreviewAssetModalType {
  LANDING_PAGE = 'landing page',
  LANDING_PAGE_TEMPLATE = 'landing page_template',
  EMAIL = 'email template',
  FORM = 'form',
}

export const createPreventFormSubmitScript = () => {
  const script = document.createElement('script')
  script.innerHTML = `
    function aoPostLoadCallbackInternal() {
      const element = document.querySelector('form');
      if (element) {
        element.addEventListener('submit', function(event) {
          event.preventDefault ? event.preventDefault() : (event.returnValue = false);
        });
      }
    }
  `
  return script
}
