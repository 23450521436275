import { TFunction } from 'i18next'

import { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { formatContactsUtils } from '@components/ContactsDetails/utils/ContactsDetails.utils'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { PageInput } from '@graphql/types/microservice/segment-types'
import { CrmEntityUiMetaData } from '@graphql/types/query-types'
import { Folder } from '@interface/Folder'
import { SegmentInput, SegmentSyncType } from '@interface/Segment'
import { FieldType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { useSegmentComposerCategorizationRequests } from '@src/pages/SegmentComposer/GraphQL/SegmentComposerRequests.categorization.graphQL'
import { useSegmentComposerClassicRequests } from '@src/pages/SegmentComposer/GraphQL/SegmentComposerRequests.classic.graphQL'
import { useSegmentComposerListRequests } from '@src/pages/SegmentComposer/GraphQL/SegmentComposerRequests.list.graphQL'
import { SEGMENT_COMPOSER_CREATE_URL, SEGMENT_COMPOSER_EDIT_URL } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { SegmentComposerState } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { caseInsensitiveAlphabeticSort, filterNotEmptyArray } from '@utils/array'
import { ItemType } from '@utils/categorization'
import { buildSegment, buildSegmentHierarchy, buildSegments, getSegmentPath } from '@utils/contactSegments/contactSegments.utils'
import { useCategorizationService } from '@utils/hooks/microservices/useCategorizationService'
import { useSegmentService } from '@utils/hooks/microservices/useSegmentService'
import useCRM from '@utils/hooks/useCRM'
import { logNewRelicError } from '@utils/new-relic.utils'

enum SegmentComposerAsset {
  SEGMENT_HIERARCHY = 'SegmentHierarchy',
  SUBSCRIPTION_CATEGORIES = 'SubscriptionCategories',
  CAMPAIGNS = 'Campaigns',
  CRM_ENTITIES = 'CrmEntities',
  SCORE_SHEETS = 'ScoreSheets',
  SENDERS = 'Senders',
  UCL_FIELDS = 'UCLFields',
}

const showFailMessage = (t: TFunction, asset: SegmentComposerAsset) => {
  return {
    showStatusToast: true,
    status: Status.FAIL,
    statusMessage: t(`SegmentComposer.StatusToast.FailMessage.${asset}`),
  }
}

const TextTypes = ['TEXT', 'EMAIL']
const NumericTypes = ['SCORE', 'NUMBER']
const DateTypes = ['DATE', 'DATETIME']
const BooleanTypes = ['BOOLEAN']

const getFieldType = (dataType: string) => {
  if (TextTypes.includes(dataType)) {
    return FieldType.TEXT
  }
  if (NumericTypes.includes(dataType)) {
    return FieldType.NUMERIC
  }
  if (DateTypes.includes(dataType)) {
    return FieldType.DATE
  }
  if (BooleanTypes.includes(dataType)) {
    return FieldType.BOOLEAN
  }
  return FieldType.TEXT
}

export const getSegmentPathBreadcrumb = (segmentPath: BreadcrumbsItemType[], name: string) => {
  return [{ text: 'All Contacts', hasTooltip: true }, ...segmentPath, { text: name, hasTooltip: true, svgName: SvgNames.segment }]
}

const getSegmentComposerEditUrl = (id: string) => {
  return `${SEGMENT_COMPOSER_EDIT_URL}/${id}`
}

const getSegmentComposerCreateUrl = (type: 'direct' | 'query', parentId?: string) => {
  return `${SEGMENT_COMPOSER_CREATE_URL}/${type}${parentId ? `/${parentId}` : ''}`
}

/**
 * Opens the Segment Composer in create mode.
 *
 * @param {('direct' | 'query')} type - The type of segment to create.
 * @param {string} [parentId] - The optional parent ID for the segment.
 */
export const openSegmentComposerToCreate = (type: 'direct' | 'query', parentId?: string) => {
  window.open(getSegmentComposerCreateUrl(type, parentId), '_blank')
}

/**
 * Opens the Segment Composer in edit mode.
 *
 * @param {string} id - The ID of the segment to edit.
 */
export const openSegmentComposerToEdit = (id: string) => {
  window.open(getSegmentComposerEditUrl(id), '_blank')
}

export const useLoadAllAssets = (
  segment: SegmentInput,
  update: (newState: Partial<SegmentComposerState>) => void,
  isNew: boolean
): { loadAllAssets: (segmentId?: string) => void } => {
  const { getAllTags, getAllFolders, getAllActOnContactsSegment } = useCategorizationService()
  const { getScoreSheetsRequest, getUnifiedFieldsRequest } = useSegmentComposerListRequests()
  const { getCampaignsRequest, getCrmEntitiesRequest, getVerifiedSendersRequest, getSubscriptionCategoriesRequest } =
    useSegmentComposerClassicRequests()
  const { getSegmentHierarchyRequest } = useSegmentComposerCategorizationRequests()
  const { getColumnsRequest, getContactsRequest } = useSegmentService()

  const { hasCRMConnected } = useCRM()

  const { t } = useTranslation()

  const loadAssets = async () => {
    const getAllTagsPromise = getAllTags({ types: [ItemType.SEGMENT] })
    const getAllFoldersPromise = getAllFolders({ type: ItemType.SEGMENT })
    const getAllActOnContactsSegmentPromise = getAllActOnContactsSegment()
    const [tags = [], folders = [], allContactsSegment] = await Promise.all([
      getAllTagsPromise,
      getAllFoldersPromise,
      getAllActOnContactsSegmentPromise,
    ])
    update({ tags, folders: folders as Folder[], allContactsSegment: allContactsSegment ? buildSegment(allContactsSegment) : undefined })
  }

  const loadUCLFields = async () => {
    try {
      const { data, errors } = await getUnifiedFieldsRequest()

      if (!!data?.unifiedListFieldMappings) {
        update({
          uclFieldsOptions: (data.unifiedListFieldMappings as UnifiedListFieldMapping[])
            .filter((field) => field && !field.hidden && !field.deleted)
            .map(({ displayName = '', dataType = '', columnIndex, standardFieldKey }) => ({
              label: displayName,
              value: columnIndex.toString(),
              extraOptions: { type: getFieldType(dataType), standardFieldKey },
            })),
        })
      } else if (errors) {
        update({ statusToast: showFailMessage(t, SegmentComposerAsset.UCL_FIELDS) })
        logNewRelicError(errors)
      }
    } catch (e) {
      update({ statusToast: showFailMessage(t, SegmentComposerAsset.UCL_FIELDS) })
      logNewRelicError(e)
    }
  }

  const loadScoreSheets = async () => {
    try {
      const { data, errors } = await getScoreSheetsRequest()
      if (!!data?.scoreSheets) {
        update({
          scoreSheetOptions: data.scoreSheets.map((scoreSheet) => ({ label: scoreSheet?.name, value: scoreSheet?.id } as SelectV2SingleOption)) || [],
        })
      } else if (errors) {
        update({ statusToast: showFailMessage(t, SegmentComposerAsset.SCORE_SHEETS) })
        logNewRelicError(errors)
      }
    } catch (e) {
      update({ statusToast: showFailMessage(t, SegmentComposerAsset.SCORE_SHEETS) })
      logNewRelicError(e)
    }
  }

  const loadSenders = async () => {
    try {
      const { data, errors } = await getVerifiedSendersRequest()
      if (!!data?.loadFromAddressesPage) {
        update({
          senderOptions: data.loadFromAddressesPage
            .filter((sender) => sender.isVerified)
            .map((sender) => ({
              label: sender.email ?? '',
              value: sender.uuid ?? '',
            }))
            .sort((a, b) => caseInsensitiveAlphabeticSort(a.label, b.label)),
        })
      } else if (errors) {
        update({ statusToast: showFailMessage(t, SegmentComposerAsset.SENDERS) })
        logNewRelicError(errors)
      }
    } catch (e) {
      update({ statusToast: showFailMessage(t, SegmentComposerAsset.SENDERS) })
      logNewRelicError(e)
    }
  }

  const loadCampaigns = async () => {
    try {
      const { data, errors } = await getCampaignsRequest()
      if (!!data?.campaigns) {
        update({
          campaignOptions:
            data.campaigns
              .filter((campaign) => campaign.id)
              .map((campaign) => ({ label: campaign.name, value: campaign.id } as SelectV2SingleOption)) || [],
        })
      } else if (errors) {
        update({ statusToast: showFailMessage(t, SegmentComposerAsset.CAMPAIGNS) })
        logNewRelicError(errors)
      }
    } catch (e) {
      update({ statusToast: showFailMessage(t, SegmentComposerAsset.CAMPAIGNS) })
      logNewRelicError(e)
    }
  }

  const loadCrmEntities = async () => {
    if (hasCRMConnected) {
      try {
        const { data, errors } = await getCrmEntitiesRequest()
        if (data?.getCrmEntities) {
          update({
            crmEntityOptions: data.getCrmEntities
              .filter(filterNotEmptyArray)
              .sort(({ displayName: a = '' }, { displayName: b = '' }) => (a > b ? 1 : -1))
              .map<SelectV2SingleOption<CrmEntityUiMetaData>>(({ entityFields = [], ...entity }) => ({
                label: entity.displayName ?? '',
                value: entity.entityType?.name ?? entity.displayName ?? '',
                extraOptions: {
                  ...entity,
                  entityFields: entityFields.filter(filterNotEmptyArray).sort(({ displayName: a = '' }, { displayName: b = '' }) => (a > b ? 1 : -1)),
                },
              })),
          })
        } else if (errors) {
          update({ statusToast: showFailMessage(t, SegmentComposerAsset.CRM_ENTITIES) })
          logNewRelicError(errors)
        }
      } catch (e) {
        update({ statusToast: showFailMessage(t, SegmentComposerAsset.CRM_ENTITIES) })
        logNewRelicError(e)
      }
    }
  }

  const loadSubscriptionCategories = async () => {
    try {
      const { data, errors } = await getSubscriptionCategoriesRequest()
      if (!!data?.getSubscriptionCategories) {
        update({
          subscriptionCategories:
            data.getSubscriptionCategories
              .filter((category) => !!category)
              .map((category) => ({ label: category?.name, value: category?.id } as SelectV2SingleOption)) || [],
        })
      } else if (errors) {
        update({ statusToast: showFailMessage(t, SegmentComposerAsset.SUBSCRIPTION_CATEGORIES) })
        logNewRelicError(errors)
      }
    } catch (e) {
      update({ statusToast: showFailMessage(t, SegmentComposerAsset.SUBSCRIPTION_CATEGORIES) })
      logNewRelicError(e)
    }
  }

  const loadSegmentHierarchy = async (segmentId?: string): Promise<SegmentInput | undefined> => {
    if (segmentId) {
      try {
        const { data, errors } = await getSegmentHierarchyRequest(segmentId)
        if (!!data?.getItemHierarchyByExternalId) {
          const segments = buildSegments(data.getItemHierarchyByExternalId)
          const segment = segments.find((item) => item?.externalId === segmentId)
          if (segment) {
            const segmentHierarchy = buildSegmentHierarchy(segments)
            const segmentPath = getSegmentPath(segmentId, segmentHierarchy)
              .filter((segment) => isNew || segment.externalId !== segmentId)
              .map(({ name }) => ({ text: name, hasTooltip: true }))
              .reverse()

            if (isNew) {
              update({ segmentHierarchy, segmentPath })
            } else {
              const segmentInput: SegmentInput = {
                folderId: segment.folderId,
                id: segment.externalId,
                labels: segment.tags,
                name: segment.name,
                description: '', // TODO: doesn't exists yet in the segment
                createdBy: 'John Doe', // TODO: the segment has the authorId, we need to get the user name
                lastModifiedBy: '', // TODO: doesn't exists yet in the segment
                createdDate: segment.createdTime ? new Date(segment.createdTime).getTime() : Date.now(),
                lastModifiedDate: segment.updatedTime ? new Date(segment.updatedTime).getTime() : Date.now(),
                syncType: SegmentSyncType.DYNAMIC,
                isDirectSelect: segment.type === 'Direct Select',
              }
              update({ segmentHierarchy, segmentPath, segment: segmentInput })
              return segmentInput
            }
          } else {
            update({ statusToast: showFailMessage(t, SegmentComposerAsset.SEGMENT_HIERARCHY) })
          }
        } else if (errors) {
          update({ statusToast: showFailMessage(t, SegmentComposerAsset.SEGMENT_HIERARCHY) })
          logNewRelicError(errors)
        }
      } catch (e) {
        update({ statusToast: showFailMessage(t, SegmentComposerAsset.SEGMENT_HIERARCHY) })
        logNewRelicError(e)
      }
    }
  }

  const getColumns = async (externalId: string, headers: string[]) => {
    const columns = await getColumnsRequest(externalId, headers)
    return columns ?? headers.map((name, id) => ({ name, id } as Column))
  }

  const loadContacts = async ({ id } = segment) => {
    const page: PageInput = {
      id,
      pageNumber: 0,
      pageSize: -1,
      search: '',
    }
    const contactsPage = await getContactsRequest(page)
    if (contactsPage) {
      const columns = id ? await getColumns(id, contactsPage.headers as string[]) : []
      const selectedContacts = formatContactsUtils(contactsPage, columns)
      update({ selectedContacts, columns })
    }
  }

  const loadAllAssets = async (segmentId?: string) => {
    try {
      const [segmentInput] = await Promise.all([
        loadSegmentHierarchy(segmentId),
        loadAssets(),
        loadCampaigns(),
        loadCrmEntities(),
        loadScoreSheets(),
        loadSenders(),
        loadSubscriptionCategories(),
        loadUCLFields(),
      ])
      if (!isNew && segmentInput?.id && segmentInput.isDirectSelect) {
        // this function should only be called while editing an existing direct select segment
        await loadContacts(segmentInput)
      }
    } finally {
      update({ loadingAssets: false })
    }
  }

  return {
    loadAllAssets,
  }
}
