import { ComponentProps, useEffect } from 'react'

import { HomepageModule, HomePageSearchBarMode } from '@thoughtspot/visual-embed-sdk'
import { AppEmbed } from '@thoughtspot/visual-embed-sdk/react'
import { useThoughtSpotService } from '@utils/hooks/microservices/useThoughtspotService'

export const useAnalyticsEmbedSandbox = () => {
  const { isSandbox, hasAIAnalytics } = useThoughtSpotService()
  const embedProps: ComponentProps<typeof AppEmbed> = isSandbox
    ? {
        modularHomeExperience: true,
        showPrimaryNavbar: false,
        homePageSearchBarMode: hasAIAnalytics ? HomePageSearchBarMode.AI_ANSWER : HomePageSearchBarMode.OBJECT_SEARCH,
        isUnifiedSearchExperienceEnabled: false,
        hiddenHomepageModules: [HomepageModule.Learning, HomepageModule.Trending],
        // Doesn't work well with sandbox environment
        fullHeight: false,
      }
    : {}

  useEffect(() => {
    // This effect replaces what the fullHeight prop is supposed to do
    if (!isSandbox) {
      return
    }
    const resizeEmbed = () => {
      const embed = document.querySelector<HTMLDivElement>(`.analytics__embed`)
      if (embed) {
        const PADDING = 30
        embed.style.height = `${window.innerHeight - embed.offsetTop - PADDING}px`
      }
    }
    resizeEmbed()
    window.addEventListener('resize', resizeEmbed)
    return () => {
      window.removeEventListener('resize', resizeEmbed)
    }
  }, [])

  return { embedProps }
}
