import React, { FC, useState } from 'react'
import { UseFormReturn, useController } from 'react-hook-form'

import classNames from 'classnames'
// eslint-disable-next-line no-restricted-imports
import { set } from 'date-fns'

import Button, { ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox/Checkbox'
import DatePicker from '@components/DatePicker/DatePicker'
import EmojiButton from '@components/EmojiButton/EmojiButton'
import InputV2 from '@components/InputV2/InputV2'
import { LabelType, LabelV2 } from '@components/LabelV2/LabelV2'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import { SelectV2GroupedOption, SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgNames } from '@components/Svg'
import { SvgType } from '@components/Svg/Svg'
import Tab from '@components/TabsAO/TabAO'
import Tabs, { TabItemData, TabStyle } from '@components/TabsAO/TabsAO'
import TextArea, { TextAreaResizeDirection } from '@components/TextArea/TextArea'
import TimePickerV2, { TimePickerV2Format } from '@components/TimePickerV2/TimePickerV2'
import TimeZoneSelectV2, { TimeZoneSelectV2Type } from '@components/TimeZoneSelectV2/TimeZoneSelectV2'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { CustomAddonModalForm } from '@src/pages/EmailComposer/utils/Addons/customAddons.types'

import LocationDetails from './components/LocationDetails/LocationDetails'
import WebinarDetails from './components/WebinarDetails/WebinarDetails'
import { DEFAULT_START_DATE, EventModalTabs } from './utils/EventModal.utils'

import './EventModal.css'

interface EventModalV2Props {
  form: UseFormReturn<CustomAddonModalForm>
  timeZoneOptions: SelectV2SingleOption[]
  onClose: Function
  addressOptions: SelectV2GroupedOption[]
  webinarOptions: SelectV2GroupedOption[]
  webinarTypeOptions: SelectV2SingleOption[]
  loading: boolean
  handleInsert: (data: CustomAddonModalForm) => void
  submitButtonText: string
  className?: string
  dataTest?: string
}

const rootClass = 'event-modal'
const textAreaStyles = { minHeight: '96px', maxHeight: '174px' }

const EventModal: FC<EventModalV2Props> = (props: EventModalV2Props) => {
  const {
    form,
    onClose,
    timeZoneOptions,
    addressOptions,
    webinarOptions,
    webinarTypeOptions,
    loading,
    handleInsert,
    submitButtonText,
    dataTest = rootClass,
  } = props

  const { t } = useTranslation()

  const {
    details: { allDay },
  } = form.getValues()

  const validateDateTime = () => {
    const { startDate, endDate, allDay, endTime, startTime } = form.getValues().details

    if (!startTime && !endTime && !allDay) {
      return false
    }

    const validate = (startDate: number, endDate: number) => {
      if (startDate > endDate) {
        return 'Event must end after it starts'
      }
    }

    if (allDay && endDate && startDate) {
      return validate(startDate.getTime(), endDate.getTime())
    } else if (!allDay && startDate && endTime) {
      const [hours, minutes] = endTime.split(':')
      const newHours = minutes.includes('PM') ? parseInt(hours) + 12 : parseInt(hours)

      const tempEndDate = set(startDate.getTime(), {
        hours: newHours,
        minutes: parseInt(minutes.split(' ')[0]),
      })

      return validate(startDate.getTime(), tempEndDate.getTime())
    }
  }

  const { field: eventName } = useController({ control: form.control, name: 'details.eventName', rules: { required: true } })
  const { field: eventDescription } = useController({ control: form.control, name: 'details.description' })
  const { field: startDate, fieldState: startDateState } = useController({
    control: form.control,
    name: 'details.startDate',
    rules: { required: true },
  })
  const { field: endDate, fieldState: endDateState } = useController({
    control: form.control,
    name: 'details.endDate',
    rules: { validate: validateDateTime },
  })
  const { field: startTime, fieldState: startTimeState } = useController({ control: form.control, name: 'details.startTime' })
  const { field: endTime, fieldState: endTimeState } = useController({
    control: form.control,
    name: 'details.endTime',
    rules: { validate: validateDateTime },
  })
  const { field: allDayEvent } = useController({ control: form.control, name: 'details.allDay' })
  const { field: timeZone } = useController({ control: form.control, name: 'details.timeZone' })
  const { field: addVideoConference } = useController({ control: form.control, name: 'details.addVideoConferencing' })
  const { field: activeTab } = useController({ control: form.control, name: 'activeTab' })

  const [inputKey, setInputKey] = useState<string>(eventName.value)
  const [showVideoConferenceCard, setShowVideoConferenceCard] = useState<boolean>(addVideoConference.value)

  const tabData: TabItemData[] = [
    {
      index: EventModalTabs.IN_PERSON,
      label: t('In person'),
      icon: SvgNames.threeUsersSelected,
      inactiveIcon: SvgNames.threeUsers,
    },
    {
      index: EventModalTabs.ONLINE,
      label: t('Online'),
      icon: activeTab.value === EventModalTabs.IN_PERSON ? SvgNames.webinarOutline : SvgNames.webinar,
    },
  ]

  const renderEventDetails = () => (
    <>
      <div className={`${rootClass}__wrapper-flex-column`}>
        <LabelV2 label={t('Event name')} labelType={LabelType.medium} withoutMargin required />
        <span className={`${rootClass}__name`}>
          <InputV2
            key={inputKey}
            required
            placeholder={t('Add event name')}
            value={eventName.value}
            onChange={eventName.onChange}
            onBlurCapture={(event) => setInputKey(event.target.value)}
            className={`${rootClass}__name-input`}
          />
          <EmojiButton
            onSelect={(emoji) => {
              const newValue = eventName.value.concat(emoji)

              eventName.onChange(newValue)
              setInputKey(newValue)
            }}
            align={'end'}
          />
        </span>
      </div>
      <div className={`${rootClass}__wrapper-flex-column`}>
        <TextArea
          withMaxLengthIcon
          labelWithoutMargin
          name={eventDescription.name}
          label={t('Event description')}
          labelType={LabelType.medium}
          defaultValue={eventDescription.value}
          textAreaStyles={textAreaStyles}
          onChange={eventDescription.onChange}
          resizeDirection={TextAreaResizeDirection.VERTICAL}
          placeholder={t('Add details about your event. Ex: topics, speakers, schedule, etc.')}
          rows={5}
          maxCharacterProps={{ maxLength: 2000 }}
        />
      </div>
      <div className={`${rootClass}__wrapper-flex-column`}>
        <div className={classNames(`${rootClass}__wrapper-flex-row`, `${rootClass}__datetime`)}>
          <DatePicker
            required
            value={startDate.value}
            minDate={DEFAULT_START_DATE.getTime()}
            onChange={(date) => {
              if (date) {
                startDate.onChange(date)
              }
            }}
            allowClear={false}
            label={'Start date'}
            className={`${rootClass}__datetime-date-picker`}
            status={startDateState.error ? 'error' : undefined}
          />
          {allDay ? (
            <>
              <Typography text={'to'} className={`${rootClass}__datetime-typography`} />
              <DatePicker
                required={allDay}
                inlineError
                value={endDate.value}
                minDate={startDate.value ? startDate.value.getTime() : undefined}
                onChange={(date) => {
                  if (date) {
                    endDate.onChange(date)
                  }
                  form.trigger('details.endDate')
                }}
                allowClear={false}
                label={'End date'}
                className={`${rootClass}__datetime-date-picker`}
                errorMessage={endDateState.error?.message ? endDateState.error.message : undefined}
                status={endDateState.error ? 'error' : undefined}
              />
            </>
          ) : (
            renderTimePickers()
          )}
        </div>
        <div className={`${rootClass}__wrapper-flex-row`}>
          <Checkbox
            label={t('All day')}
            onChange={(value) => {
              if (!value) {
                endDate.onChange(undefined)
              }

              allDayEvent.onChange(value)
            }}
            checked={allDayEvent.value}
            dataTest={`${dataTest}-all-day`}
            className={`${rootClass}__checkbox`}
          />
          <div className={`${rootClass}__separator`} />
          <TimeZoneSelectV2
            type={TimeZoneSelectV2Type.MINIMAL}
            label={timeZone.value?.label ?? ''}
            selectProps={{
              options: timeZoneOptions,
              value: timeZone.value,
              onChange: (option) => option && timeZone.onChange(option),
            }}
          />
        </div>
      </div>
    </>
  )

  const renderTimePickers = () => (
    <>
      <TimePickerV2
        isParentModal
        onSubmit={async (time) => {
          if (time) {
            const hours = time.get('hour')
            const minutes = time.get('minute')

            startDate.value && startDate.onChange(set(startDate.value, { hours, minutes }))
            startTime.onChange(`${hours}:${minutes}`)
            form.trigger('details.endTime')
          }
        }}
        labelKey={'Start time'}
        required
        defaultValue={startTime.value}
        format={TimePickerV2Format.HH_MM}
        allowClear={false}
        dataTest={`${dataTest}-start-time`}
        className={`${rootClass}__datetime-time-picker`}
        status={startTimeState.error ? 'error' : undefined}
      />
      <Typography text={'to'} className={`${rootClass}__datetime-typography`} />
      <TimePickerV2
        isParentModal
        inlineError
        onSubmit={async (time) => {
          if (time) {
            const hours = time.get('hour')
            const minutes = time.get('minute')

            if (allDay && endDate.value) {
              endDate.onChange(set(endDate.value, { hours, minutes }))
            }

            endTime.onChange(`${hours}:${minutes}`)

            form.trigger('details.endTime')
          }
        }}
        labelKey={'End time'}
        required
        defaultValue={endTime.value}
        format={TimePickerV2Format.HH_MM}
        allowClear={false}
        className={`${rootClass}__datetime-time-picker`}
        errorMessage={endTimeState.error?.message ? endTimeState.error.message : undefined}
        status={endTimeState.error?.message ? 'error' : undefined}
      />
    </>
  )

  return (
    <Modal
      header={<ModalHeaderV2 headerText={t('Create an event')} headerType={ModalHeaderType.Form} className={`${rootClass}__modal-header`} />}
      dataTest={dataTest}
      className={rootClass}
      isOpen
      paddingV2
    >
      <ModalBody>
        {loading ? (
          <Loader loaderType={LoaderTypes.page} center />
        ) : (
          <Tabs
            tabStyle={TabStyle.PILL}
            defaultValue={activeTab.value}
            onChange={(tab) => activeTab.onChange(tab as EventModalTabs)}
            childData={tabData}
            className={`${rootClass}__tabs`}
          >
            <Tab index={EventModalTabs.IN_PERSON} className={`${rootClass}__content`}>
              <>
                {renderEventDetails()}
                <LocationDetails form={form} addressOptions={addressOptions} />
                {showVideoConferenceCard ? (
                  <WebinarDetails
                    withHeader
                    form={form}
                    webinarTypeOptions={webinarTypeOptions}
                    webinarOptions={webinarOptions}
                    onHeaderClose={() => {
                      addVideoConference.onChange(false)
                      setShowVideoConferenceCard(false)
                    }}
                    selectedTab={activeTab.value}
                  />
                ) : (
                  <Button
                    buttonType={ButtonType.OUTLINE}
                    fullWidth
                    onClick={() => {
                      setShowVideoConferenceCard(true)
                      form.trigger()
                      addVideoConference.onChange(true)
                    }}
                    className={`${rootClass}__add-video-conferencing`}
                    dataTest={`${rootClass}-add-video-conferencing`}
                  >
                    <Svg name={SvgNames.webinarOutline} type={SvgType.EXTRA_LARGE_ICON} />
                    {t('Add video conferencing')}
                  </Button>
                )}
              </>
            </Tab>
            <Tab index={EventModalTabs.ONLINE} className={`${rootClass}__content`}>
              {renderEventDetails()}
              <WebinarDetails form={form} webinarTypeOptions={webinarTypeOptions} webinarOptions={webinarOptions} selectedTab={activeTab.value} />
            </Tab>
          </Tabs>
        )}
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        onClose={() => onClose()}
        buttons={{
          cancelButtonLabel: t('Cancel'),
          actionButtonOnClick: form.handleSubmit(handleInsert, () => form.trigger()),
          actionButtonLabel: submitButtonText,
          actionButtonDisabled: !form.formState.isValid,
        }}
      />
    </Modal>
  )
}

export default EventModal
