import { gql } from '@apollo/client'

export default gql`
  query getCrmEntities($listId: String!) {
    getCrmEntities(listId: $listId) {
      entityType {
        name
      }
      displayName
      entityFields {
        fieldName
        displayName
        dataType
        pickListValues
      }
    }
  }
`
