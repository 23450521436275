import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import EmptyListing, { EmptyListingProps, EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { disableClicks, getAssetPreviewMode, getAssetPreviewUrl, PreviewAssetModalType } from '@components/PreviewAssetModal/PreviewAssetModal.utils'
import Spinner from '@components/Spinner/Spinner'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { isHtmlEmpty } from '@utils/isHtmlEmpty'

import { PreviewAssetModalProps } from './PreviewAssetModal'
import { createPreventFormSubmitScript } from './PreviewAssetModal.utils'

import './PreviewAssetModal.css'

export type PreviewAssetModalContentProps = Pick<
  PreviewAssetModalProps,
  | 'assetSrc'
  | 'previewHtml'
  | 'title'
  | 'error'
  | 'loading'
  | 'assetType'
  | 'isStory'
  | 'checkIsPreviewEmpty'
  | 'checkIsPreviewEmptyTimeout'
  | 'dataTest'
  | 'emptyListingProps'
> & {
  onError?: (hasError: boolean) => void
  onFullHeight?: (value: boolean) => void
}
const MAX_WAIT_TIME = 5000
const TRIES_COUNT = 10
const rootClass = 'preview-asset-modal'

const PreviewAssetModalContent: FC<PreviewAssetModalContentProps> = (props) => {
  const {
    onError,
    onFullHeight,
    assetSrc,
    previewHtml,
    title,
    error,
    loading,
    assetType,
    isStory,
    emptyListingProps,
    checkIsPreviewEmpty,
    checkIsPreviewEmptyTimeout,
    dataTest = rootClass,
  } = props
  const { t } = useTranslation()
  const noAssetProvided = !(assetSrc || previewHtml)
  const [loadError, setLoadError] = useState<boolean>(false)
  const [isLoadingContent, setIsLoadingContentState] = useState(!error && !noAssetProvided)
  const setIsLoadingContent = (value: boolean) => {
    setIsLoadingContentState(value)
    isLoadingContentRef.current = value
  }
  const [iframeHeight, setIframeHeight] = useState<number | string>('100%')
  const [isEmptyHtml, setIsEmptyHtml] = useState<boolean>(false)
  const [iframeHtmlElement, setIframeHtmlElement] = useState<HTMLHtmlElement | null>(null)

  const isLoadingContentRef = useRef<boolean>(isLoadingContent)
  const loadingInterval = useRef<NodeJS.Timeout>()
  const loadingStartedTime = useRef(0)
  const contentRef = useRef<HTMLIFrameElement>(null)

  const previewMode = getAssetPreviewMode(assetSrc, checkIsPreviewEmpty)
  const previewUrl = getAssetPreviewUrl(previewMode, assetSrc)
  const showError = error || loadError
  const assetTypeText = previewMode === 'image' ? 'image' : assetType || 'asset'
  const showLoading = loading || isLoadingContent

  const maxWaitTime = checkIsPreviewEmptyTimeout ?? MAX_WAIT_TIME

  useEffect(() => {
    clearInterval(loadingInterval.current)
    if (!noAssetProvided) {
      if (previewMode !== 'async') {
        loadingStartedTime.current = new Date().valueOf()
        loadingInterval.current = setInterval(() => {
          const isTimedOut = new Date().valueOf() - loadingStartedTime.current >= maxWaitTime
          if (isTimedOut && isLoadingContentRef.current) {
            setIsLoadingContent(false)
            setLoadError(true)
            clearInterval(loadingInterval.current)
          }
        }, 1000)
      }
      setLoadError(false)
    }
  }, [assetSrc, previewHtml])

  useEffect(() => {
    if (iframeHtmlElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        const iframe = entries[0]
        setIframeHeight(iframe.target.scrollHeight)
      })

      if (iframeHtmlElement.clientWidth !== 0 && iframeHtmlElement.clientHeight !== 0) {
        resizeObserver.observe(iframeHtmlElement)
      }

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [iframeHtmlElement])

  const handleError = useCallback(() => {
    setLoadError(true)
  }, [])

  const checkIframeContent = useCallback(
    (tryNumber = 0) => {
      if (tryNumber < TRIES_COUNT) {
        const iframeContent = contentRef.current?.contentDocument
        if (!iframeContent || (checkIsPreviewEmpty && isHtmlEmpty(iframeContent.body))) {
          new Promise((resolve) => setTimeout(resolve, maxWaitTime / TRIES_COUNT)).then(() => checkIframeContent(++tryNumber))
        } else {
          const html = iframeContent.querySelector('html')
          if (html) {
            onFullHeight?.(false)
            setIframeHeight(html.offsetHeight)
            setIframeHtmlElement(html)
          }
          setIsLoadingContent(false)
        }
      } else {
        setIsEmptyHtml(true)
        setIsLoadingContent(false)
      }
    },
    [previewHtml, checkIsPreviewEmpty, onFullHeight]
  )

  const onLoad = () => {
    if (previewMode === 'async') {
      if (!contentRef.current?.contentDocument) {
        onFullHeight?.(true)
        setIsEmptyHtml(true)
        setIsLoadingContent(false)
      } else {
        setIsLoadingContent(true)
        checkIframeContent()
      }
    } else {
      onFullHeight?.(true)
      setIsLoadingContent(false)
    }
    if (contentRef.current && (assetType === PreviewAssetModalType.LANDING_PAGE || assetType === PreviewAssetModalType.LANDING_PAGE_TEMPLATE)) {
      const iframeDocument = contentRef.current.contentDocument || contentRef.current.contentWindow?.document
      if (iframeDocument) {
        const script = createPreventFormSubmitScript()
        iframeDocument.body.appendChild(script)
      }
    }
  }

  useEffect(() => {
    onError?.(showError)
  }, [showError, error, onError])

  const emptyListingFields: Partial<EmptyListingProps> =
    previewMode !== 'async'
      ? {
          text: t('Preview.Asset.Modal.Text.File', { assetType: assetTypeText, context: 'error' }),
          linkText: t('Preview.Asset.Modal.Text.File.Link', { assetType: assetTypeText, context: 'error' }),
          link: getAssetPreviewUrl(previewMode, assetSrc, false),
          hideIcon: false,
        }
      : {}

  const emptyListing = (
    <EmptyListing
      text={t('Preview.Asset.Modal.Text', { assetType: assetTypeText, context: showError ? 'error' : isEmptyHtml ? 'emptyHtml' : 'empty' })}
      headline={t('Preview.Asset.Modal.Headline', { assetType: assetTypeText, context: showError ? 'error' : 'empty' })}
      textType={TextType.BODY_TEXT_LIGHT}
      imgSrc={showError ? StaticImageNames.errorStateAllContacts : StaticImageNames.emptySearch}
      size={EmptyListingSize.MEDIUM}
      isStory={isStory}
      withoutBorder
      dataTest={`${dataTest}-body-error`}
      {...emptyListingFields}
      {...emptyListingProps}
    />
  )

  return (
    <div className={`${rootClass}__body-content`} data-test={`${dataTest}-body-content`}>
      {!showLoading && (noAssetProvided || isEmptyHtml || showError) ? (
        emptyListing
      ) : (
        <>
          {showLoading && <Spinner className={`${rootClass}__loader`} dataTest={`${dataTest}-loader`} />}
          {previewMode === 'image' ? (
            <img
              style={{ display: showLoading ? 'none' : undefined }}
              onError={handleError}
              src={assetSrc}
              className={`${rootClass}__body-image`}
              data-test={`${dataTest}-body-image`}
              alt={title}
              onLoad={onLoad}
            />
          ) : previewMode === 'pdf' ? (
            <object data={assetSrc} type={'application/pdf'} onLoad={onLoad} className={`${rootClass}__body-pdf`}>
              {emptyListing}
            </object>
          ) : (
            <iframe
              // need iFrame to exist in modal, but do not display in loading state
              style={{ display: showLoading ? 'none' : undefined }}
              ref={contentRef}
              height={iframeHeight}
              onError={handleError}
              onLoad={onLoad}
              src={previewUrl}
              srcDoc={previewHtml ? disableClicks(previewHtml) : undefined}
              className={`${rootClass}__body-iframe`}
              data-test={`${dataTest}-body-iframe`}
              title={title}
              sandbox="allow-scripts allow-modals allow-popups allow-downloads allow-same-origin allow-forms"
            />
          )}
        </>
      )}
    </div>
  )
}

export default PreviewAssetModalContent
