import dayjs from 'dayjs'
import pretty from 'pretty'

import { KeyValue } from '@components/KeyValuePicker/KeyValuePicker'
import { SendDetailsResponse } from '@graphql/types/query-types'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { getSendToContacts, getSendToLists } from '@src/pages/EmailComposer/utils/EmailComposerRecipients.utils'
import { IEntityContentJsonExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import {
  SendMethod as ContextSendMethod,
  DEFAULT_EMAIL_TITLE,
  defaultEmailComposerState,
  EmailComposerState,
  EmailMessage,
  SendTimeType,
  SendtoList,
} from '@utils/composer/context/EmailComposer.context'
import { mockEmailBeeTemplate } from '@utils/composer/emailComposer/templates/beeTemplate'
import { ExtendedMessageType, messageConfigurationSettings, MessageConfigurationType } from '@utils/composer/emailComposer/types'
import { mockLandingPageBeeTemplate } from '@utils/composer/landingPage/templates/beeTemplate'
import {
  PERSONALIZED_FROM_ADDRESS_OPTION_VALUE,
  SALESFORCE_OWNER_OPTION_VALUE,
  UNSPECIFIED_SENDER_OPTION_VALUE,
} from '@utils/composer/settings.constants'
import { DeepPartial } from '@utils/types'

import { RetrieveMessageById } from './EmailComposer.types'
import {
  getMessageConfigurationType,
  getTimeStringFromHourAndMinute,
  processCurrentDateForLaterOrTimezone,
  processCurrentDateForStaggerOrAdaptive,
} from './EmailComposer.utils'
import { getWebinarCategoryMessageConfigurations, MessageConfigurationWebinarType } from './MessageType.settings'
import {
  getWebinarHTMLFields,
  hasWebinarBlock,
} from '../EmailModals/components/ManageWebinarDetailsModal/components/WebinarBlock/utils/webinarBlock.utils'

export const prepareJsonForBeeEditor = (beeEditorPage?: string, isValid?: boolean, isLandingPage?: boolean): IEntityContentJsonExtended => {
  if (!isValid || !beeEditorPage) {
    return isLandingPage ? mockLandingPageBeeTemplate : mockEmailBeeTemplate
  }
  try {
    return JSON.parse(beeEditorPage)
  } catch (e) {
    return isLandingPage ? mockLandingPageBeeTemplate : mockEmailBeeTemplate
  }
}

const getRecipients = (isCreatedNewMessage = false, isNewAssetReportMessage = false, message: EmailMessage, sendDetails?: SendDetailsResponse) => {
  const { sendToContacts, sendToLists } = sendDetails ?? {}
  const { sendto_contacts, sendto_lists } = message ?? {}

  if (isCreatedNewMessage && !isNewAssetReportMessage) {
    return {
      contacts: sendto_contacts,
      lists: sendto_lists,
    }
  }

  return {
    contacts: sendToContacts?.length ? getSendToContacts(sendToContacts) ?? sendto_contacts : undefined,
    lists: sendToLists?.length ? getSendToLists(sendToLists) ?? sendto_lists : undefined,
  }
}

export const retrieveMessageHelper = ({
  setDefaultValues,
  message,
  userId,
  accountId,
  containerValuesRef,
  isFirstEdit,
  isPlainTextOnly: isPlainTextOnlyFromRetrieve,
}: RetrieveMessageById): DeepPartial<EmailComposerState> => {
  const {
    detectedURLChanges: { hasStartId, hasToParam, isNewMessage, isNewAssetReportMessage, isBlankMessage },
    landingPage: { isLandingPage },
  } = containerValuesRef.current

  const { beeEditorPage, emailContentHtml, sendDetails, settings, lastEdited, isValidJson, messageType, id: tempId } = message
  const {
    actOnCampaignId,
    analyticsTrackingListWithPersonalization,
    isTemplateAvailableInEBE,
    isTransactionalMessage,
    preview,
    senderUUID,
    senderEmail,
    fallbackSenderEmail,
    fallbackSenderUUID,
    replyToUUID,
    subject,
    subscriptionCategoryId,
    title,
    hasPrePopulatedUTMParams,
    customTextPart,
    abTestIsRunning,
    abTestsSent,
    webinarConfig,
    isPlainTextOnly,
    isCustomHtml,
    lastUpdatedBy,
  } = settings ?? {}

  const {
    overrideFatigueRules,
    suppressDuplicates,
    suppressLists,
    individualRecipientsSourceIds,
    restricteLists,
    assetReportRecipientDetails: assetReportDetails,
  } = sendDetails ?? {}

  const { isEmailWebinar, isEmailRSS, isEmailABTest } = detectEmailType(messageType)
  const messageConfigurationTypeUrl = getMessageConfigurationType() || ''
  const isEmailResend = messageConfigurationTypeUrl === 'resend' //on resend messages,first retrieve messagetype is original messageType
  const sendMethod = isFirstEdit && isEmailResend ? {} : sendDetails?.sendMethod
  const {
    launchType,
    staggeredLaunchEnd,
    staggeredLaunchStart,
    scheduleTimestamp,
    scheduleTimeZone,
    sendingPeriodEndHour,
    sendingPeriodEndMinute,
    isCreateCRMMsgSentNotes,
    sendingPeriodStartHour,
    sendingPeriodStartMinute,
    adaptiveLaunchEnd,
    adaptiveLaunchStart,
  } = sendMethod ?? {}

  let sendDetailsFieldsToUpdate: Partial<ContextSendMethod> = {}

  const analyticsFields: KeyValue[] = analyticsTrackingListWithPersonalization?.map((el) => ({ key: el?.parameter, value: el?.value })) ?? []

  const isCreatedNewMessage = containerValuesRef.current.isCreatedNewMessage || isNewMessage
  const plainTextMode = isPlainTextOnly || isPlainTextOnlyFromRetrieve

  const givenDate = dayjs(scheduleTimestamp)
  const messageTitle = setDefaultValues || !title?.length ? DEFAULT_EMAIL_TITLE : title
  const currentTimezoneDate = scheduleTimeZone ? dayjs().tz(scheduleTimeZone) : dayjs()
  const currentTimezoneHour = currentTimezoneDate.get('hour')
  const currentTimezoneMinute = currentTimezoneDate.get('m')
  const currentTimezoneAmOrPm = currentTimezoneDate.format('A')
  const givenAmOrPm = sendingPeriodStartHour && sendingPeriodStartHour > 11 ? 'PM' : 'AM'
  const checkTime = currentTimezoneHour >= (sendingPeriodStartHour ?? 0) && currentTimezoneMinute >= (sendingPeriodStartMinute ?? 0)

  const resetProps: Partial<ContextSendMethod> = {
    sendtype: undefined,
    sendtime: undefined,
    senddate: undefined,
    staggertimestart: undefined,
    staggertimeend: undefined,
  }

  switch (launchType) {
    case 'STANDARD':
      if (scheduleTimestamp && scheduleTimeZone) {
        sendDetailsFieldsToUpdate = {
          sendtype: SendTimeType.LATER,
          senddate: new Date(scheduleTimestamp),
          sendtimezone: scheduleTimeZone,
          sendtime: getTimeStringFromHourAndMinute(sendingPeriodStartHour, sendingPeriodStartMinute),
        }

        sendDetailsFieldsToUpdate = processCurrentDateForLaterOrTimezone({
          currentTimezoneDate,
          givenDate,
          currentTimezoneAmOrPm,
          givenAmOrPm,
          checkTime,
          sendDetailsFieldsToUpdate,
          resetProps,
        })
      } else if (scheduleTimeZone) {
        sendDetailsFieldsToUpdate = { sendtype: SendTimeType.LATER }
      } else {
        sendDetailsFieldsToUpdate = { sendtype: SendTimeType.STANDARD }
      }

      break
    case 'STAGGERED':
      const staggerStart = dayjs(staggeredLaunchStart)
      const staggerEnd = dayjs(staggeredLaunchEnd)

      sendDetailsFieldsToUpdate = {
        sendtype: SendTimeType.STAGGERED,
        staggertimestart: getTimeStringFromHourAndMinute(sendingPeriodStartHour, sendingPeriodStartMinute),
        staggertimeend: getTimeStringFromHourAndMinute(sendingPeriodEndHour, sendingPeriodEndMinute),
        staggertimezone: scheduleTimeZone,
        staggerdatestart: staggeredLaunchStart ? staggerStart : undefined,
        staggerdateend: staggeredLaunchEnd ? staggerEnd : undefined,
      }

      sendDetailsFieldsToUpdate = processCurrentDateForStaggerOrAdaptive({
        start: staggerStart,
        end: staggerEnd,
        currentTimezoneDate,
        fieldToOverride: 'staggerdatestart',
        resetProps,
        sendDetailsFieldsToUpdate,
      })

      sendDetailsFieldsToUpdate = sendDetailsFieldsToUpdate === resetProps ? { sendtype: SendTimeType.STAGGERED } : sendDetailsFieldsToUpdate

      break
    case 'TIME_ZONE':
      sendDetailsFieldsToUpdate = {
        sendtype: SendTimeType.TIME_ZONE,
        senddate: scheduleTimestamp ? new Date(scheduleTimestamp) : undefined,
        sendtime: getTimeStringFromHourAndMinute(sendingPeriodStartHour, sendingPeriodStartMinute),
        defaultsendtimezone: scheduleTimeZone,
      }

      sendDetailsFieldsToUpdate = processCurrentDateForLaterOrTimezone({
        currentTimezoneDate,
        givenDate,
        currentTimezoneAmOrPm,
        givenAmOrPm,
        checkTime,
        sendDetailsFieldsToUpdate,
        resetProps,
      })

      sendDetailsFieldsToUpdate =
        sendDetailsFieldsToUpdate.sendtype === undefined
          ? { sendtype: SendTimeType.TIME_ZONE, defaultsendtimezone: scheduleTimeZone }
          : sendDetailsFieldsToUpdate

      break
    case 'ADAPTIVE':
      const adaptiveStart = dayjs(adaptiveLaunchStart)
      const adaptiveEnd = dayjs(adaptiveLaunchEnd)

      sendDetailsFieldsToUpdate = {
        sendtype: SendTimeType.ADAPTIVE,
        adaptivedatestart: adaptiveLaunchStart ? adaptiveStart : undefined,
        adaptivedateend: adaptiveLaunchEnd ? adaptiveEnd : undefined,
      }

      sendDetailsFieldsToUpdate = processCurrentDateForStaggerOrAdaptive({
        start: adaptiveStart,
        end: adaptiveEnd,
        currentTimezoneDate,
        fieldToOverride: 'adaptivedatestart',
        resetProps,
        sendDetailsFieldsToUpdate,
      })

      sendDetailsFieldsToUpdate = sendDetailsFieldsToUpdate === resetProps ? { sendtype: SendTimeType.ADAPTIVE } : sendDetailsFieldsToUpdate

      break
    default:
      sendDetailsFieldsToUpdate = {}
      break
  }

  const isLoggedInUserSelected = (sender?: string) => sender === ''
  const isSenderUnspecified = (sender?: string) => sender === UNSPECIFIED_SENDER_OPTION_VALUE
  const isSalesForce = senderEmail === SALESFORCE_OWNER_OPTION_VALUE
  const isPersonalized = senderEmail === PERSONALIZED_FROM_ADDRESS_OPTION_VALUE

  // According to old Composer logic
  const sender = isLoggedInUserSelected(senderEmail)
    ? userId
    : isSenderUnspecified(senderEmail)
    ? undefined
    : isSalesForce
    ? SALESFORCE_OWNER_OPTION_VALUE
    : isPersonalized
    ? PERSONALIZED_FROM_ADDRESS_OPTION_VALUE
    : senderUUID

  // According to old Composer logic
  const fallbackSender = isLoggedInUserSelected(fallbackSenderEmail)
    ? userId
    : isSenderUnspecified(fallbackSenderEmail)
    ? undefined
    : fallbackSenderUUID

  const assetReportRecipientDetails = (isCreatedNewMessage && isNewAssetReportMessage) || !isCreatedNewMessage ? assetReportDetails : undefined

  const { contacts, lists } = getRecipients(isCreatedNewMessage, isNewAssetReportMessage, containerValuesRef?.current.message, sendDetails)

  /* 
   We are handling messageTypes and configurations in this composerURLIdHandler function.
   There are some cases when we need to handle correct configurations while redirecting from classic pages (triggered messages).
   If messageConfiguration param is absent than this should work for specified cases.
  */

  const specificCases: Partial<ExtendedMessageType | undefined>[] = ['FORM_AUTO_RESPONSE', 'PROGRAM_MESSAGE', 'AB_TEST_MESSAGE']
  const webinarTypes = ['WEBINAR_SINGLE', 'WEBEX_MULTI', 'GOTOWEBINAR_MULTI', 'WEBINAR_MESSAGE']
  let computedWebinarType
  let computedMessageType
  const webinarMessageType = beeEditorPage && JSON.parse(beeEditorPage)?.webinar?.type
  const messageConfigurationType = messageConfigurationTypeUrl?.toUpperCase() || messageType
  const isWebinarType = webinarTypes.includes(messageConfigurationType)

  if (!messageConfigurationTypeUrl || isWebinarType) {
    computedWebinarType = webinarConfig?.messageCategory?.toUpperCase() as keyof typeof MessageConfigurationWebinarType
  }

  if (!(hasStartId || hasToParam || isNewMessage) && !messageConfigurationTypeUrl) {
    computedMessageType = (
      specificCases.includes(messageType)
        ? MessageConfigurationType[messageType as keyof typeof MessageConfigurationType]
        : webinarMessageType
        ? webinarMessageType.toUpperCase()
        : ''
    ) as keyof typeof MessageConfigurationType
  }

  const messageConfiguration = computedWebinarType
    ? getWebinarCategoryMessageConfigurations(webinarConfig?.name, webinarConfig?.type, computedWebinarType)
    : computedMessageType
    ? messageConfigurationSettings[computedMessageType as MessageConfigurationType]
    : undefined

  const autoSave = messageConfiguration?.saveButton?.autoSaveWithSeparateSaveAndClose
    ? false
    : messageConfiguration?.saveButton?.separateSaveAndClose
    ? true
    : containerValuesRef.current.disabledFeatures.autoSave

  const parsedJson = prepareJsonForBeeEditor(beeEditorPage, isValidJson, isLandingPage)
  const webinarData = parsedJson.webinar ? { webinar: parsedJson.webinar } : {}

  /* 
    For Webinars we need to update JSON html here manually with updated fields because on BEE side they have an issue on customAddons function.
    Custom addons handler only works when we click on manage button in composer or in sidebar. 
    https://actonit.slack.com/archives/C05RRNKTS8Z/p1718908444363279
  */
  // The second part is needed to be checked in this way for webinar DRAFTS messages
  if (isEmailWebinar || hasWebinarBlock(parsedJson) || messageConfiguration?.messageType === 'WEBINAR_MESSAGE') {
    getWebinarHTMLFields(parsedJson as IEntityContentJsonExtended, accountId, message.id ?? '', false, webinarConfig)
  }

  const isWebinarScheduled = messageConfiguration?.messageType === 'WEBINAR_MESSAGE' && (parsedJson.webinar?.scheduledTime ?? 0) !== 0
  const programId = parsedJson.webinar?.programId
  const groupId = parsedJson.webinar?.groupId
  const taskId = parsedJson.webinar?.taskId

  const needToSetIdFromRetrieve =
    tempId &&
    (messageConfigurationType === MessageConfigurationType.BLANK_MESSAGE || (!isCreatedNewMessage && (isEmailWebinar || isEmailABTest || isEmailRSS)))

  // When message is created from blank message the BE send us falsy values because they cant send null or undegined; for that reason we are checking isBlankMessage to set default value as true
  const isSuppresDupsEnabled =
    (messageConfigurationType === MessageConfigurationType.DRAFT && messageType === MessageConfigurationType.TEMPLATE) || isBlankMessage

  return {
    lastEdited,
    loading: false,
    // make required fields empty
    lastSavedTitle: messageTitle,
    ...(messageConfiguration && { messageConfiguration }),
    detectedURLChanges: {
      plainTextMode,
      uploadHtmlMode: isCustomHtml,
    },
    disabledFeatures: { autoSave },
    message: {
      ...(!isCreatedNewMessage && { messageType }),
      // for A/B, RSS, Blank Message we need to get 'id' property from retrieve data and set it in messageData
      ...(needToSetIdFromRetrieve && { id: tempId }),
      ...((isCustomHtml || isPlainTextOnly) && { lastUpdatedBy }),
      retrieveMessageType: messageType,
      isAbTestRunning: abTestIsRunning,
      isAbTestsSent: abTestsSent,
      webinarConfig,
      isWebinarScheduled,
      programId,
      groupId,
      taskId,
      sender,
      title: messageTitle,
      subject: subject ?? '',
      senderUUID: sender,
      fallbackSender,
      analyticsFields,
      subscriptionCategoryId,
      hasPrePopulatedUTMParams,
      templateJson: parsedJson,
      previewText: preview ?? '',
      isTransactional: isTransactionalMessage,
      isTriggered: isTemplateAvailableInEBE,
      campaignId: actOnCampaignId,
      isCrmSentNote: isCreateCRMMsgSentNotes,
      templateHtml: emailContentHtml ? pretty(emailContentHtml) : '',
      overrideFatigueRules: overrideFatigueRules ?? defaultEmailComposerState.message.overrideFatigueRules,
      suppress_dups: isSuppresDupsEnabled || (suppressDuplicates ?? defaultEmailComposerState.message.suppress_dups),
      sendto_lists: lists,
      sendto_contacts: contacts,
      replyToSender: replyToUUID,
      suppress_lists: suppressLists as SendtoList[],
      individualRecipientsSourceIds,
      customTextPart: customTextPart ?? '',
      restricteLists,
      isPlainTextOnly: isPlainTextOnly || plainTextMode,
      sendMethod: {
        sendtime: getTimeStringFromHourAndMinute(sendingPeriodStartHour, sendingPeriodStartMinute),
        ...sendDetailsFieldsToUpdate,
      },
      assetReportRecipientDetails,
      ...webinarData,
    },
  }
}
