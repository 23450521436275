import React, { FC, useContext, useMemo } from 'react'
import { DndProvider } from 'react-dnd'

import classNames from 'classnames'

import ButtonIcon, { ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import { SvgNames } from '@components/Svg'
import Switch from '@components/Switch/Switch'
import { HeaderAction } from '@components/TableV2/tableV2TS/interfaces'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { GroupDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import FloatingActionHeader from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/FloatingActionHeader/FloatingActionHeader'
import SegmentComposerDragLayer from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentComposerDragLayer/SegmentComposerDragLayer'
import SegmentDefinition from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/SegmentDefinition'
import {
  combineRowsOptions,
  getAddButtonOptions,
  GroupAddButtonAction,
  renderAddButton,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { useSegmentDefinitionActions } from '@src/pages/SegmentComposer/hooks/useSegmentDefinitionActions'
import { LogicalOperator } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'
import { dragAndDropManager } from '@utils/dnd-utils'

import './QuerySegmentBuilder.css'

interface QuerySegmentBuilderProps {
  className?: string
  dataTest?: string
  // TODO: only for VQA in storybook, remove it after implementing the backend integration
  isEmpty?: boolean
}

const rootClass = 'query-segment-builder'

const QuerySegmentBuilder: FC<QuerySegmentBuilderProps> = (props: QuerySegmentBuilderProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    update,
    values: { selectedRows, segmentDefinition },
  } = useContext(SegmentComposerContext)

  const { group } = segmentDefinition
  const { addRow, addGroup } = useSegmentDefinitionActions()

  const isEmpty = (!group?.rows || group?.rows.length === 0) && (!group?.subGroups || group?.subGroups.length === 0)

  /*TODO: replace with actual data representation*/
  const disableGroupingAction = useMemo(() => {
    const { groups, hasUngroupedRow } = selectedRows.reduce(
      (result, rowId) => {
        if (rowId.includes('-')) {
          result.groups.add(rowId.split('-')[0])
        } else {
          result.hasUngroupedRow = true
        }
        return result
      },
      { groups: new Set<string>(), hasUngroupedRow: false }
    )
    return groups.size > 1 || (groups.size > 0 && hasUngroupedRow)
  }, [selectedRows])

  const { t } = useTranslation()

  const getAllGroupIds = (group: GroupDefinitionDto): string[] => {
    const groupIds = [group.groupId]
    if (group.subGroups) {
      group.subGroups.forEach((subGroup) => {
        groupIds.push(...getAllGroupIds(subGroup as GroupDefinitionDto))
      })
    }
    return groupIds
  }

  const handleExpandCollapseAll = (isExpanded: boolean) => {
    update({
      collapsedGroups: isExpanded ? [] : getAllGroupIds(group as GroupDefinitionDto),
    })
  }

  const headerOptions: HeaderAction[] = [
    {
      icon: SvgNames.turnIntoGroup,
      label: disableGroupingAction ? 'SegmentComposer.Build.Header.Actions.Group.Disabled' : 'SegmentComposer.Build.Header.Actions.Group',
      onClick: () => undefined,
      hasTooltip: true,
      disabled: disableGroupingAction,
    },
    { icon: SvgNames.copyStep, label: 'SegmentComposer.Build.Header.Actions.Duplicate', onClick: () => undefined, hasTooltip: true },
    { icon: SvgNames.delete, label: 'SegmentComposer.Build.Header.Actions.Delete', onClick: () => undefined, hasTooltip: true },
  ]

  const handleOnAddActionClick = (action: GroupAddButtonAction) => {
    const groupAddActionHandlers = {
      [GroupAddButtonAction.ADD_ROW]: () => addRow(group as GroupDefinitionDto, segmentDefinition),
      [GroupAddButtonAction.ADD_GROUP]: () => addGroup(group as GroupDefinitionDto, segmentDefinition),
      [GroupAddButtonAction.ADD_SAVED_GROUP]: () => false,
    }
    groupAddActionHandlers[action]()
  }

  const addButtonOptions = getAddButtonOptions(t, handleOnAddActionClick, false)

  const renderEmptyDefinition = () => (
    <div className={`${rootClass}__definition-empty`}>
      <Typography text={t('SegmentComposer.Build.EmptyDefinition')} type={TextType.BODY_TEXT} />
    </div>
  )

  return (
    <DndProvider manager={dragAndDropManager}>
      <SegmentComposerDragLayer />
      <div className={classNames(rootClass, className)} data-test={dataTest}>
        {selectedRows.length > 0 && (
          <FloatingActionHeader
            actions={headerOptions}
            headerActionCountText={t('SegmentComposer.Build.Header.CountLabel', { selected: selectedRows.length })}
          />
        )}
        <div className={`${rootClass}__switch-container`}>
          <div className={`${rootClass}__switch-container-right`}>
            <Typography text={t('SegmentComposer.Build.CombineRows.Label')} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} />
            <Switch
              dataTest={`${dataTest}-combine-switch`}
              options={combineRowsOptions}
              selected={group?.logicalOperator || combineRowsOptions[0].label}
              onClick={(value) =>
                update({
                  segmentDefinition: {
                    ...segmentDefinition,
                    group: { ...group, logicalOperator: value } as GroupDefinitionDto,
                  },
                })
              }
              isToggle
            />
            <InfoTooltip
              tooltipClassName={`${rootClass}__switch-info-tooltip`}
              text={t('SegmentComposer.Build.CombineRows.Tooltip')}
              minimalPadding={false}
            />
          </div>
          <div className={`${rootClass}__switch-container-left`}>
            <Tooltip
              trigger={
                <ButtonIcon
                  icon={SvgNames.expandAll}
                  type={ButtonIconType.LIGHT_TEAL_ON_HOVER}
                  onClick={(event) => {
                    event.currentTarget.blur()
                    handleExpandCollapseAll(true)
                  }}
                  dataTest={`${dataTest}-expand-all`}
                />
              }
            >
              {t('SegmentComposer.Build.Expand.All')}
            </Tooltip>
            <Tooltip
              trigger={
                <ButtonIcon
                  icon={SvgNames.collapseAll}
                  type={ButtonIconType.LIGHT_TEAL_ON_HOVER}
                  onClick={(event) => {
                    event.currentTarget.blur()
                    handleExpandCollapseAll(false)
                  }}
                  dataTest={`${dataTest}-collapse-all`}
                />
              }
              alignTextCenter
            >
              {t('SegmentComposer.Build.Collapse.All')}
            </Tooltip>
          </div>
        </div>
        <div className={`${rootClass}__definition-container`}>
          {isEmpty ? (
            renderEmptyDefinition()
          ) : (
            <SegmentDefinition builderElement={group} operator={group.logicalOperator as LogicalOperator} hasDragAndDrop />
          )}
        </div>
        {renderAddButton(t, addButtonOptions)}
      </div>
    </DndProvider>
  )
}

export default QuerySegmentBuilder
