import React, { useCallback, useEffect, useRef, useState } from 'react'
import AceEditor from 'react-ace'
import { DndProvider } from 'react-dnd'

import Button, { ButtonType } from '@components/Button'
import ButtonCopy from '@components/ButtonCopy/ButtonCopy'
import ButtonIcon, { ButtonIconSize, ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import { Ace } from '@components/CodeEditor/Ace'
import DraggableItem, { DraggableElement } from '@components/DraggableItem/DraggableItem'
import DragLayer from '@components/DragLayer/DragLayer'
import Svg, { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { LandingPageCssFile } from '@graphql/types/mutation-types'
import { LandingPageJsFile } from '@graphql/types/query-types'
import { updateOrAddStylesAndScripts } from '@src/pages/EmailComposer/utils/EmailComposerAPI.utils'
import { ItemType } from '@utils/categorization'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { useTranslation } from '@utils/const/globals'
import { dragAndDropManager } from '@utils/dnd-utils'

const rootClass = 'landing-page-composer-custom-java-script'

interface LandingPageComposerCustomEditorProps {
  mode: 'javascript' | 'css'
  value: string
  onChange: (code: string) => void
  onSelectButtonClick: () => void
  title: string
  description: string
  editorTitle: string
  buttonText: string
  copyText: string
  dataTest?: string
}

const LandingPageComposerCustomEditor = ({
  mode,
  value,
  onChange,
  onSelectButtonClick,
  title,
  description,
  editorTitle,
  buttonText,
  dataTest = rootClass,
}: LandingPageComposerCustomEditorProps) => {
  const [isUndoEnabled, setIsUndoEnabled] = useState<boolean>(false)
  const [isRedoEnabled, setIsRedoEnabled] = useState<boolean>(false)
  const editorRef = useRef<AceEditor>(null)

  const {
    values: {
      message: { templateHtml },
      landingPage: { selectedCss, selectedScripts },
      isBeeLoading,
      loading,
      customTabFirstRender,
      showRefreshPreviewModal,
    },
    api: { update },
  } = useComposerContext()

  const { t } = useTranslation()

  useEffect(() => {
    const editor = editorRef.current?.editor
    const session = editor?.getSession()
    const undoManager = session?.getUndoManager()

    const updateUndoRedoStatus = () => {
      if (undoManager) {
        setIsUndoEnabled(undoManager.hasUndo())
        setIsRedoEnabled(undoManager.hasRedo())
      }
    }

    editor?.on('change', () => {
      if (customTabFirstRender) {
        update({ customTabFirstRender: false })
      }

      updateUndoRedoStatus()
    })

    return () => {
      editor?.off('change', updateUndoRedoStatus)
    }
  }, [customTabFirstRender])

  const handleUndo = () => {
    const editor = editorRef.current?.editor

    if (editor) {
      editor.undo()
      setIsRedoEnabled(editor.getSession().getUndoManager().hasRedo())
    }
  }

  const handleRedo = () => {
    const editor = editorRef.current?.editor
    if (editor) {
      editor.redo()
      setIsUndoEnabled(editor.getSession().getUndoManager().hasUndo())
    }
  }

  const copyCode = () => {
    navigator.clipboard.writeText(value || '')
  }

  const selectedMedia = mode === 'css' ? selectedCss ?? [] : selectedScripts ?? []

  const onDropChoice = (movedChoice: DraggableElement, droppedAt: number) => {
    if (!selectedMedia) return

    const rawChoices = [...selectedMedia.slice(0, droppedAt), movedChoice, ...selectedMedia.slice(droppedAt)]

    rawChoices.splice(movedChoice.index + (movedChoice.index < droppedAt ? 0 : 1), 1)

    const newChoices = rawChoices.map((choice, index) => ({ ...choice, index }))

    update({
      landingPage: {
        ...(mode === 'css' ? { selectedCss: newChoices as LandingPageCssFile[] } : { selectedScripts: newChoices as LandingPageJsFile[] }),
      },
    })
  }

  const onRemoveChoice = (choice: DraggableElement) => {
    const updatedMedia = (selectedMedia as (LandingPageCssFile | LandingPageJsFile)[]).filter((_, index) => index !== choice.index)

    update({
      landingPage: {
        ...(mode === 'css' ? { selectedCss: updatedMedia as LandingPageCssFile[] } : { selectedScripts: updatedMedia as LandingPageJsFile[] }),
      },
    })
  }

  const renderSelectedItems = useCallback(() => {
    return selectedMedia?.map((item, index) => {
      const dragItem = { name: item.name ?? '', index, id: item.id, content: item.content }
      return (
        <DraggableItem
          key={dragItem.index}
          item={dragItem}
          listingPosition={dragItem.index + 1}
          className={`${rootClass}__choice`}
          onDrop={onDropChoice}
          onRemove={onRemoveChoice}
          canBeRemoved
          showListingPosition={false}
        />
      )
    })
  }, [selectedMedia])

  useEffect(() => {
    if (!isBeeLoading && !loading) {
      const updatedHtml = updateOrAddStylesAndScripts(selectedCss ?? [], selectedScripts ?? [], templateHtml, mode)

      update({
        message: { templateHtml: updatedHtml },
        preview: { html: updatedHtml },
      })
    }
  }, [mode, selectedCss, selectedScripts, isBeeLoading, loading])

  useEffect(() => {
    if (showRefreshPreviewModal) {
      editorRef.current?.editor.blur()
    } else {
      editorRef.current?.editor.focus()
    }
  }, [showRefreshPreviewModal])

  const onButtonMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
  }

  return (
    <div className={rootClass}>
      <Typography
        className={`${rootClass}__title`}
        text={t(title)}
        type={TextType.BODY_TEXT}
        weight={TextWeight.MEDIUM}
        dataTest={`${dataTest}-title`}
      />
      <Typography className={`${rootClass}__desc`} text={t(description)} type={TextType.BODY_TEXT_SMALL_LIGHT} dataTest={`${dataTest}-desc`} />
      {!!selectedMedia?.length && (
        <div className={`${rootClass}__selected-media`} data-test={`${dataTest}-selected-media`}>
          <DndProvider manager={dragAndDropManager}>
            <DragLayer itemType={ItemType.POLL_CHOICE} dataTest={`${dataTest}-drag-layer`} />
            {renderSelectedItems()}
          </DndProvider>
        </div>
      )}
      <Button className={`${rootClass}__button`} buttonType={ButtonType.INFO} onClick={onSelectButtonClick} dataTest={`${dataTest}-select-button`}>
        <Svg name={SvgNames.plus} />
        {t(buttonText)}
      </Button>
      <Typography className={`${rootClass}__editor-title`} text={t(editorTitle)} type={TextType.BODY_TEXT} weight={TextWeight.MEDIUM} />
      <Ace
        className={`${rootClass}__editor`}
        aceRef={editorRef}
        content={value}
        mode={mode}
        theme="xcode"
        onCodeChange={onChange}
        editorProps={{ $blockScrolling: false }}
        options={{
          highlightGutterLine: true,
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showFoldWidgets: true,
          tabSize: 2,
          showPrintMargin: false,
          highlightSelectedWord: false,
          scrollPastEnd: false,
          wrap: true,
          indentedSoftWrap: true,
          cursorStyle: 'ace',
          newLineMode: 'auto',
          mergeUndoDeltas: false,
          useWorker: true,
        }}
        height="504px"
        width="100%"
        fontSize={14}
      />
      <div className={`${rootClass}__editor-footer`}>
        <ButtonCopy
          onClick={copyCode}
          disabled={!value}
          className={`${rootClass}__editor-footer-actions-copy`}
          dataTest={`${dataTest}-copy-button`}
        />
        <div className={`${rootClass}__editor-footer-actions`}>
          <Tooltip
            trigger={
              <ButtonIcon
                type={ButtonIconType.TERTIARY}
                icon={isUndoEnabled ? SvgNames.actionUndoEnabled : SvgNames.actionUndoDisabled}
                size={ButtonIconSize.LARGE}
                onClick={handleUndo}
                disabled={!isUndoEnabled}
                onMouseDown={onButtonMouseDown}
                dataTest={`${dataTest}-undo-button`}
              />
            }
            position={'top'}
          >
            {isUndoEnabled ? t('Undo') : t('EmailComposer.Preview.Plain.Text.Edit.NoUndo')}
          </Tooltip>
          <Tooltip
            trigger={
              <ButtonIcon
                type={ButtonIconType.TERTIARY}
                icon={isRedoEnabled ? SvgNames.actionRedoEnabled : SvgNames.actionRedoDisabled}
                size={ButtonIconSize.LARGE}
                onClick={handleRedo}
                disabled={!isRedoEnabled}
                onMouseDown={onButtonMouseDown}
                dataTest={`${dataTest}-redo-button`}
              />
            }
            position={'top'}
          >
            {isRedoEnabled ? t('Redo') : t('EmailComposer.Preview.Plain.Text.Edit.NoRedo')}
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default LandingPageComposerCustomEditor
