import { useCallback, useEffect, useState } from 'react'

import equal from 'fast-deep-equal/es6'

import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { ListingPageSubType } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { LandingPageStatus } from '@complex/ListingPage/Utils/ListingPage.constants'
import { legacyActonContext, rootContext } from '@const/globals'
import getLandingPageQuery from '@graphql/queries/getLandingPage'
import { EmbeddedForm, GetLandingPageQuery, GetLandingPageQueryVariables, LandingPageResponse } from '@graphql/types/query-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

import { filterNotEmptyArray } from './array'
import { LandingPage } from './composer/context/LandingPageComposer.context'
import { landingPagesDisabledStatusFilter, landingPagesEnabledStatusFilter } from './filter'

export type RequiredEmbeddedForm = Required<Omit<EmbeddedForm, '__typename'>>

export interface LandingPageType extends LandingPageResponse {
  canEdit: boolean
  embeddedForms: RequiredEmbeddedForm[]
  publishInfo?: Partial<LandingPage>
}

const containsFormSubType: ListingPageSubType = { name: 'contains_form', label: 'Contains a form', hasAndCondition: true }

export const LANDING_PAGE_BEE_COMPOSER_URL = `${rootContext}/content/landingPage`

export const landingPageTemplateSubTypes: ListingPageSubType[] = [{ ...containsFormSubType }]

export const landingPageSubTypes: ListingPageSubType[] = [
  { name: LandingPageStatus.ENABLED, label: landingPagesEnabledStatusFilter.name, hasAndCondition: true },
  { name: LandingPageStatus.DISABLED, label: landingPagesDisabledStatusFilter.name, hasAndCondition: true },
  { ...containsFormSubType },
]

export const LANDING_PAGE_BUILDER_LINK = `${rootContext}/classic/if/legacy/lp`
export const LANDING_PAGE_SOCIAL_PUBLISH_LINK = `${legacyActonContext}/socialpub/compose.jsp`
export const LANDING_PAGE_SEO_REPORT_LINK = `${legacyActonContext}/_seo/seoReport.jsp`

export const openLandingPageEditorWindow = (lpId: string, isBeeComposer?: boolean, windowName = '', isTemplate?: boolean) => {
  const URL = isBeeComposer
    ? `${LANDING_PAGE_BEE_COMPOSER_URL}/${lpId}/${isTemplate ? 'design' : 'settings'}`
    : `${LANDING_PAGE_BUILDER_LINK}/${lpId}`
  return window.open(URL, windowName)
}

export const openLandingPageSocialPublishWindow = (lpId: string, publicURL = '') => {
  return window.open(`${LANDING_PAGE_SOCIAL_PUBLISH_LINK}?pgId=${lpId}${publicURL ? `&pgUrl=${publicURL}` : ''}`)
}

export const openLandingPageSEOReportWindow = (lpId: string) => {
  return window.open(`${LANDING_PAGE_SEO_REPORT_LINK}?id=page:${lpId}`)
}

export const openCreateLandingPageFromTemplateWindow = (templateId: string, windowName = '') => {
  return window.open(`${legacyActonContext}/ng-ui/editor/#!/startFrom/${templateId}?folderId=-1`, windowName)
}

export interface LandingPageBuilderMessage {
  lpId: string
  title: string
}

export const isLandingPageBuilderMessage = (data: MessageEvent<any>['data']) => data instanceof Object && 'lpId' in data && 'title' in data

export const getLandingPagePreviewUrl = (lpId: string, isTemplate?: boolean) =>
  `${legacyActonContext}/internalapi/landingpage/preview/${lpId}?type=${isTemplate ? 'LANDING_PAGE_TEMPLATE' : 'LANDING_PAGE'}`

const filterEmbeddedForms = (forms?: (EmbeddedForm | undefined)[]): RequiredEmbeddedForm[] => {
  if (!forms) {
    return []
  }
  return (forms.filter((form) => filterNotEmptyArray(form) && form.id && form.name) as RequiredEmbeddedForm[]).sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
}

export const useLandingPage = (
  id: string
): {
  loadingLP: boolean
  updatingLP: boolean
  landingPage: LandingPageType | undefined
  updateLandingPage: (landingPage: Partial<LandingPageType>) => void
  refetchLP: () => void
} => {
  const client = useApolloClient()
  const { userAllowedToCreateContent } = useAccountSettings()
  const [landingPage, setLandingPage] = useState<LandingPageType>()
  const [updatingLP, setUpdatingLP] = useState<boolean>(false)

  const { loading, error, data, refetch } = useQuery<GetLandingPageQuery, GetLandingPageQueryVariables>(getLandingPageQuery, {
    client,
    fetchPolicy: 'network-only',
    variables: { id },
  })

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
    if (!loading && data?.getLandingPage) {
      setLandingPage((cur) => {
        const embeddedForms = filterEmbeddedForms(data.getLandingPage?.embeddedForms)
        if (!cur) {
          return { ...data.getLandingPage, embeddedForms, canEdit: userAllowedToCreateContent }
        } else if (!equal(cur, { ...data.getLandingPage, canEdit: userAllowedToCreateContent })) {
          setUpdatingLP(true)
          setTimeout(() => {
            setUpdatingLP(false)
          }, 1000)
          return { ...data.getLandingPage, embeddedForms, canEdit: userAllowedToCreateContent }
        }
        return cur
      })
    }
  }, [error, loading, data, userAllowedToCreateContent])

  const updateLandingPage = useCallback(
    (landingPage: Partial<LandingPageType>) => setLandingPage((cur) => ({ ...cur, ...landingPage } as LandingPageType)),
    []
  )

  return {
    loadingLP: loading,
    updatingLP,
    landingPage,
    updateLandingPage,
    refetchLP: refetch,
  }
}

export const useFetchLandingPageLazy = (landingPageId: string | undefined, allowedToFetch: boolean) => {
  const client = useApolloClient()
  const [showToggle, setShowToggle] = useState(false)
  const [lpPublishedAt, setLpPublishedAt] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)

  const [getLandingPage] = useLazyQuery<GetLandingPageQuery, GetLandingPageQueryVariables>(getLandingPageQuery, {
    client,
    fetchPolicy: 'no-cache',
    variables: { id: landingPageId || '' },
  })

  const fetchData = useCallback(async () => {
    if (!landingPageId) {
      setShowToggle(false)
      return
    }

    try {
      setIsLoading(true)
      const { data } = await getLandingPage({ variables: { id: landingPageId } })
      const LPData = data?.getLandingPage
      const shouldShowToggle = LPData?.publishInfo?.isLandingPagePublished && LPData?.publishInfo?.hasUnpublishedChanges
      setLpPublishedAt(LPData?.publishInfo?.lastPublishedAt ?? 0)
      setShowToggle(!!shouldShowToggle)
    } finally {
      setIsLoading(false)
    }
  }, [landingPageId, getLandingPage])

  useEffect(() => {
    if (allowedToFetch) {
      fetchData()
    }

    return () => {
      setIsLoading(false)
      setShowToggle(false)
    }
  }, [fetchData, allowedToFetch])

  return {
    showToggle,
    loading: isLoading,
    lpPublishedAt,
  }
}
