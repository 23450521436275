import { createContext } from 'react'

import { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { ContactDetailsContainerProps } from '@components/ContactsDetails/ContactsDetailsContainer'
import { SegmentError } from '@components/SegmentSaveModal/SegmentSaveModal'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { Status } from '@components/StatusToast/StatusToast'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { SegmentDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import { PageInput } from '@graphql/types/microservice/segment-types'
import { CrmEntityUiMetaData } from '@graphql/types/query-types'
import { Folder } from '@interface/Folder'
import { ListPageStatusToast } from '@interface/ListPage.context'
import { SegmentInput } from '@interface/Segment'
import { FactorType, LogicalOperator, ROOT_GROUP_ID, SaveType, SegmentComposerTab } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { Contact } from '@utils/contact/contact.constants'
import { Segment } from '@utils/contactSegments/contactSegments.utils'

export interface OnSaveClickParams {
  isSavingFromModal?: boolean
  type?: SaveType
  segmentInput?: SegmentInput
}

interface DirectSelectSegmentComposerState {
  allContactsSegment?: Segment
  columns?: Column[]
  columnsToSave?: ContactDetailsContainerProps['columnsToSave']
  defaultSelectedContacts: Contact[]
  parentContactsPage: PageInput
  isViewingSelectedContacts: boolean
  selectedContacts: Contact[]
  selectedContactsPage: PageInput
}

interface QuerySegmentComposerState {
  crmEntityOptions?: SelectV2SingleOption<CrmEntityUiMetaData>[]
}

export interface SegmentComposerState extends DirectSelectSegmentComposerState, QuerySegmentComposerState {
  errors: Set<SegmentError>
  folders: Folder[]
  isClosing: boolean
  isSaving?: boolean
  loadingAssets: boolean
  selectedRows: string[]
  collapsedGroups: string[]
  segment: SegmentInput
  segmentHierarchy: Segment[]
  segmentPath: BreadcrumbsItemType[]
  showFirstSaveModal: boolean
  showErrorsModal: boolean
  statusToast: ListPageStatusToast
  tags: LabelDto[]
  currentTab: string
  hasUnsavedChanges: boolean
  segmentDefinition: SegmentDefinitionDto
  scoreSheetOptions: SelectV2SingleOption[]
  campaignOptions: SelectV2SingleOption[]
  senderOptions: SelectV2SingleOption[]
  subscriptionCategories: SelectV2SingleOption[]
  uclFieldsOptions: SelectV2SingleOption[]
}

export interface SegmentComposerAPI {
  onSaveClick: (params: OnSaveClickParams) => Promise<SegmentInput | undefined>
  values: SegmentComposerState & { isNew: boolean; segmentId?: string }
  update: (newState: Partial<SegmentComposerState>) => void
}

export const segmentDefinitionInitialValues: SegmentDefinitionDto = {
  description: 'segment description',
  id: 's-1234',
  name: 'Segment Name',
  group: {
    logicalOperator: LogicalOperator.And,
    name: 'Root',
    groupId: ROOT_GROUP_ID,
    position: 1,
    rows: [
      {
        factor: {
          type: FactorType.Behavior,
        },
        ordinal: 1,
        rowId: 'row-1',
        position: 1,
      },
      {
        factor: {
          type: FactorType.Profile,
          profileFactor: {
            fieldColumnIndex: 1,
          },
        },
        ordinal: 2,
        rowId: 'row-2',
        position: 2,
      },
      {
        factor: {
          type: FactorType.Sms,
        },
        ordinal: 3,
        rowId: 'row-3',
        position: 3,
      },
    ],
    subGroups: [
      {
        logicalOperator: LogicalOperator.And,
        name: 'Group-1',
        groupId: 'group-1',
        position: 1,
        rows: [
          {
            factor: {
              type: FactorType.Behavior,
            },
            ordinal: 4,
            rowId: 'row-1-1',
            position: 1,
          },
          {
            factor: {
              type: FactorType.Crm,
            },
            ordinal: 5,
            rowId: 'row-1-2',
            position: 2,
          },
        ],
        subGroups: [
          {
            logicalOperator: LogicalOperator.And,
            name: 'Group-1-1',
            groupId: 'group-1-1',
            position: 1,
            rows: [
              {
                factor: {
                  type: FactorType.Behavior,
                },
                ordinal: 6,
                rowId: 'row-1-1-1',
                position: 1,
              },
              {
                factor: {
                  type: FactorType.Crm,
                },
                ordinal: 7,
                rowId: 'row-1-1-2',
                position: 2,
              },
            ],
            subGroups: [],
          },
        ],
      },
    ],
  },
}

export const segmentComposerInitialState: Omit<SegmentComposerState, 'segment'> = {
  defaultSelectedContacts: [],
  errors: new Set(),
  folders: [],
  parentContactsPage: {},
  currentTab: SegmentComposerTab.BUILD,
  isClosing: false,
  isViewingSelectedContacts: false,
  loadingAssets: true,
  selectedContacts: [],
  selectedContactsPage: {},
  selectedRows: [],
  collapsedGroups: [],
  showFirstSaveModal: false,
  showErrorsModal: false,
  statusToast: {
    statusMessage: '',
    status: Status.SUCCESS,
    showStatusToast: false,
  },
  tags: [],
  hasUnsavedChanges: false,
  segmentDefinition: segmentDefinitionInitialValues,
  scoreSheetOptions: [],
  campaignOptions: [],
  senderOptions: [],
  subscriptionCategories: [],
  uclFieldsOptions: [],
  segmentHierarchy: [],
  segmentPath: [],
}

export const SegmentComposerContext = createContext<SegmentComposerAPI>({ values: segmentComposerInitialState } as any)
