import React, { FC, ReactNode, useMemo } from 'react'

import classNames from 'classnames'

import {
  AutoSaveIndicator,
  AutoSaveIndicatorProps,
} from '@complex/GlobalComposerHeader/components/ComposerHeader/components/AutoSaveIndicator/AutoSaveIndicator'
import Button, { ButtonType } from '@components/Button/Button'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import InlineEditing, { InlineEditingProps } from '@components/InlineEditing/InlineEditing'
import Pill, { PillSize, PillType } from '@components/Pill/Pill'
import SplitButton, { SplitButtonOptionsProps, SplitButtonType } from '@components/SplitButton/SplitButton'
import { SvgNames } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
// eslint-disable-next-line no-restricted-imports
import Logo from '@src/components/Navigation/components/Logo'
import { useAccountSettings } from '@utils/account/account.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { THEME } from '@utils/theme'

import './ComposerHeader.css'

type CloseButtonProps = {
  type: ButtonType
  text: string
  onClick: () => void
  disabled?: boolean
}

export type ComposerHeaderProps = {
  className?: string
  dataTest?: string
  headerStatus?: ReactNode
  hasSaveIndicator?: boolean
  logoLink: string
  isNonRedirectingLogo?: boolean
  splitButtonOptions?: SplitButtonOptionsProps[]
  splitButtonType?: SplitButtonType
  showBetaPill?: boolean
  closeButtonProps?: CloseButtonProps
  inlineEditingProps: InlineEditingProps
} & AutoSaveIndicatorProps

const rootClass = 'global-composer-header-top'
const MAX_WIDTH = 700
const MIN_WIDTH = 128
const MAX_LENGTH = 100

export const ComposerHeader: FC<ComposerHeaderProps> = (props: ComposerHeaderProps) => {
  const {
    isNonRedirectingLogo = false,
    logoLink,
    hasSaveIndicator = false,
    headerStatus,
    splitButtonOptions,
    splitButtonType,
    hasPopoverCard,
    onPopoverCardButtonClick,
    onIndicatingAutoSaveEnds,
    hasPublishStatus,
    isAutoSaveDisabled,
    showBetaPill = true,
    closeButtonProps,
    inlineEditingProps,
    isCustomerCareLogin,
    hasUnsavedChanges,
    isSaving,
    dataTest = rootClass,
    className = '',
  } = props

  const { t } = useTranslation()
  const { isCustomLogo, appLogo, isMicrosoftStartPage, userSettings, customLogos } = useAccountSettings()
  const {
    values: {
      isIndicatingAutoSave,
      autoSaveFailure,
      lastEdited,
      haveUnsavedChanges,
      landingPage: { isLandingPage },
      messageConfiguration: { showOnlyDiscardButton, saveButton },
    },
    api: { onAutoSave, updateModal, onSave, onClose, update },
  } = useComposerContext()

  const isDisabled = (showOnlyDiscardButton || saveButton?.separateDiscardAndPublish) && haveUnsavedChanges === undefined
  const isSeparateSaveAndClose = isCustomerCareLogin

  const autoSaveIndicatorProps: AutoSaveIndicatorProps = useMemo(
    () => ({
      lastEdited,
      isIndicatingAutoSave,
      autoSaveFailure,
      hasPopoverCard,
      onPopoverCardButtonClick,
      onIndicatingAutoSaveEnds,
      hasPublishStatus,
      isAutoSaveDisabled,
      hasUnsavedChanges,
      isSaving,
      onAutoSave,
      update,
      updateModal,
    }),
    [
      autoSaveFailure,
      hasPopoverCard,
      hasPublishStatus,
      hasUnsavedChanges,
      isAutoSaveDisabled,
      isIndicatingAutoSave,
      isSaving,
      lastEdited,
      onAutoSave,
      onIndicatingAutoSaveEnds,
      onPopoverCardButtonClick,
      update,
      updateModal,
    ]
  )

  return (
    <div
      className={classNames(className, {
        [`${rootClass}__customer-care`]: isCustomerCareLogin,
        [`${rootClass} ${rootClass}__remove-padding`]: !isCustomerCareLogin,
      })}
    >
      {isCustomerCareLogin && (
        <InfoStatus
          status={InfoStatusTypes.Warning}
          message={<Typography text={t('ComposerHeader.CustomerCare.Banner')} tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }} />}
          svgName={SvgNames.cautionError}
          className={`${rootClass}__customer-care`}
        />
      )}
      <div className={rootClass}>
        <div className={`${rootClass}__nav flex-align-center`}>
          <Logo
            expanded={false}
            theme={THEME.LIGHT}
            isCustomLogo={isCustomLogo}
            appLogo={appLogo}
            isMicrosoftStartPage={isMicrosoftStartPage}
            userDesktop={userSettings.userDesktop}
            customLogos={customLogos}
            pageSpecificLink={logoLink}
            isNonRedirectingLogo={isNonRedirectingLogo}
          />
          <InlineEditing
            className={`${rootClass}__title-editor flex-align-center`}
            maxWidth={MAX_WIDTH}
            minWidth={MIN_WIDTH}
            maxLength={isLandingPage ? 400 : MAX_LENGTH}
            dataTest={dataTest}
            highlightOnFocus
            {...inlineEditingProps}
          >
            {headerStatus && (
              <div className={`${rootClass}__status-wrapper`} data-test={`${dataTest}-status-wrapper`}>
                {headerStatus}
              </div>
            )}
            {showBetaPill && <Pill type={PillType.SECONDARY} text={t('BETA')} size={PillSize.LARGE} noMargin></Pill>}
          </InlineEditing>
        </div>
        <div className={`${rootClass}__right flex-align-center`}>
          {hasSaveIndicator && <AutoSaveIndicator {...autoSaveIndicatorProps} />}

          {isSeparateSaveAndClose && (
            <div className={`${rootClass}__save-close`} data-test={`${rootClass}-separate-header-buttons`}>
              <Tooltip
                hide={!isDisabled}
                position="bottom"
                trigger={
                  <Button
                    buttonType={saveButton?.separateDiscardAndPublish && haveUnsavedChanges === undefined ? ButtonType.SECONDARY : ButtonType.WHITE}
                    onClick={onClose}
                    dataTest={`${dataTest}-close-only`}
                    disabled={isDisabled}
                  >
                    {t(saveButton?.separateDiscardAndPublish ? 'Discard' : 'Close')}
                  </Button>
                }
              >
                {t('Composer.Discard.Disabled.Tooltip')}
              </Tooltip>
              <Tooltip
                hide={!isDisabled}
                position="bottom"
                trigger={
                  <Button
                    buttonType={ButtonType.PRIMARY}
                    onClick={() => onSave(saveButton?.pureSave)}
                    dataTest={`${dataTest}-save-only`}
                    disabled={isDisabled}
                  >
                    {t(saveButton?.separateDiscardAndPublish ? 'Publish changes' : 'Save')}
                  </Button>
                }
              >
                {t('Composer.Publish.Disabled.Tooltip')}
              </Tooltip>
            </div>
          )}

          {!isSeparateSaveAndClose && (
            <div className={`${rootClass}__buttons`}>
              {closeButtonProps && (
                <Tooltip
                  hide={!closeButtonProps.disabled}
                  position="bottom"
                  trigger={
                    <Button
                      buttonType={closeButtonProps.type}
                      onClick={closeButtonProps.onClick}
                      dataTest={`${dataTest}-close`}
                      disabled={closeButtonProps.disabled}
                    >
                      {t(closeButtonProps.text)}
                    </Button>
                  }
                >
                  {t('ComposerHeader.Discard.Disabled.Tooltip')}
                </Tooltip>
              )}
              {splitButtonOptions && (
                <SplitButton options={splitButtonOptions} buttonType={splitButtonType} dataTest={`${dataTest}-split`} largeDropdown />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
