import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { ButtonType } from '@components/Button'
import ConfirmationModal, { YesNo } from '@components/ConfirmationModal'
import FormRow from '@components/FormRow'
import Grid from '@components/Grid/Grid'
import InputV2 from '@components/InputV2/InputV2'
import Label from '@components/Label'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AddressResponse } from '@graphql/types/query-types'

import './AddressModal.css'

interface Props {
  isOpen: boolean
  modalTitle?: string
  actionButtonLabel?: string
  onSave: (address: AddressResponse, oldName: string) => void
  handleModalClose: () => void
  canDelete?: boolean
  onDelete?: () => void
  address?: AddressResponse
  isDefault?: boolean
  hasRequiredFields?: boolean
  dataTest?: string
}

const rootClass = 'edit-address-modal'

const AddressModal: FC<Props> = (props: Props) => {
  const {
    isOpen,
    modalTitle,
    actionButtonLabel,
    onSave,
    handleModalClose,
    canDelete = true,
    onDelete,
    address,
    isDefault = false,
    hasRequiredFields,
    dataTest = rootClass,
  } = props

  const { t } = useTranslation()
  const isEditing = useMemo<boolean>(() => !!address, [address])
  const title = useMemo<string>(
    () => modalTitle || `${isEditing ? 'Edit' : 'Add'}${isDefault ? ' default account' : ''} address`,
    [isDefault, isEditing, modalTitle]
  )
  const [addressForm, setAddressForm] = useState<AddressResponse>(address ?? {})
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const isValidState = useMemo<boolean>(() => {
    const { name, street, city, zip, state, country } = addressForm
    if (hasRequiredFields) {
      return [...(!canDelete ? [] : [name]), street, city, zip].every((val) => !!val)
    } else {
      return [...(!canDelete ? [] : [name]), street, city, zip, state, country].some((val) => !!val)
    }
  }, [addressForm, canDelete, hasRequiredFields])
  const disableSave = useMemo<boolean>(() => !isTouched || !isValidState, [isTouched, isValidState])

  useEffect(() => {
    if (isOpen) {
      setAddressForm(address ?? {})
      setIsTouched(false)
    }
  }, [address, isOpen])

  const handleSave = useCallback(() => {
    handleModalClose()
    onSave(addressForm, address?.name ?? '')
  }, [handleModalClose, onSave, addressForm, address?.name])

  const onInputChange = useCallback(
    (value: string, key: keyof AddressResponse) => {
      setIsTouched(true)
      setAddressForm((current) => ({ ...current, [key]: value.trim() }))
    },
    [setAddressForm]
  )

  const onConfirmAnswer = useCallback(
    (answer: YesNo) => {
      if (answer === YesNo.YES) {
        onDelete && onDelete()
      }
      setConfirmModalOpen(false)
    },
    [onDelete]
  )

  return (
    <Modal className={rootClass} isOpen={isOpen} paddingV2 header={<ModalHeaderV2 headerText={t(title)} headerType={ModalHeaderType.Form} />}>
      <ModalBody>
        {!isDefault && canDelete && (
          <FormRow>
            {isEditing ? (
              <>
                <Label>{t('Address name')}</Label>
                <Typography text={addressForm.name} type={TextType.BODY_TEXT_LIGHT} lineHeight={LineHeight.MEDIUM_LARGE} />
              </>
            ) : (
              <InputV2
                required
                labelProps={{ label: t('Address name'), required: hasRequiredFields }}
                placeholder={t('Address name')}
                value={addressForm.name ?? ''}
                dataTest={`${dataTest}-input-name`}
                onChange={(event) => onInputChange(event.target.value, 'name')}
              />
            )}
          </FormRow>
        )}
        <FormRow>
          <InputV2
            required
            labelProps={{ label: t('Street address'), required: hasRequiredFields }}
            placeholder={t('Street address line 1')}
            value={addressForm.street ?? ''}
            dataTest={`${dataTest}-input-street`}
            onChange={(event) => onInputChange(event.target.value, 'street')}
          />
        </FormRow>
        <FormRow>
          <InputV2
            required
            labelProps={{ label: t('Street address line 2') }}
            placeholder={t('Street address line 2')}
            value={addressForm.street2 ?? ''}
            dataTest={`${dataTest}-input-street2`}
            onChange={(event) => onInputChange(event.target.value, 'street2')}
          />
        </FormRow>
        <Grid row className={`${rootClass}-grid-row`}>
          <Grid size={50}>
            <FormRow>
              <InputV2
                required
                labelProps={{ label: t('City'), required: hasRequiredFields }}
                placeholder={t('City')}
                value={addressForm.city ?? ''}
                dataTest={`${dataTest}-input-city`}
                onChange={(event) => onInputChange(event.target.value, 'city')}
              />
            </FormRow>
          </Grid>
          <Grid size={50}>
            <FormRow>
              <InputV2
                required
                labelProps={{ label: t('State / Province') }}
                placeholder={t('Add a state or province')}
                value={addressForm.state ?? ''}
                dataTest={`${dataTest}-input-state`}
                onChange={(event) => onInputChange(event.target.value, 'state')}
              />
            </FormRow>
          </Grid>
        </Grid>
        <Grid row className={`${rootClass}-grid-row`}>
          <Grid size={50}>
            <FormRow>
              <InputV2
                required
                labelProps={{ label: t('Postal code'), required: hasRequiredFields }}
                placeholder={t('ZIP/Postal code')}
                value={addressForm.zip ?? ''}
                dataTest={`${dataTest}-input-zip`}
                onChange={(event) => onInputChange(event.target.value, 'zip')}
              />
            </FormRow>
          </Grid>
          <Grid size={50}>
            <FormRow>
              <InputV2
                required
                labelProps={{ label: t('Country') }}
                placeholder={t('Country')}
                value={addressForm.country ?? ''}
                dataTest={`${dataTest}-input-country`}
                onChange={(event) => onInputChange(event.target.value, 'country')}
              />
            </FormRow>
          </Grid>
        </Grid>
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        onClose={handleModalClose}
        buttons={{
          cancelButtonLabel: t('Cancel'),
          actionButtonDisabled: disableSave,
          actionButtonLabel: actionButtonLabel ?? t('Save'),
          actionButtonOnClick: handleSave,
        }}
        deleteButton={!isDefault && canDelete && address ? { onDelete: () => setConfirmModalOpen(true) } : undefined}
      />
      <ConfirmationModal
        isYesNo
        isDelete
        isOpen={confirmModalOpen}
        title={t('Are you sure?')}
        deleteButtonText={'Delete address'}
        deleteButtonType={ButtonType.ERASE}
        body={
          <>
            <Typography text={t('Confirm.Address.Delete.1stSentence')} type={TextType.BODY_TEXT_LIGHT} inline />
            &nbsp;
            <Typography text={t('Confirm.Address.Delete.2ndSentence')} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.BOLD} inline />
          </>
        }
        onAnswer={onConfirmAnswer}
      />
    </Modal>
  )
}

export default AddressModal
