import React, { useEffect, useRef, useState } from 'react'

import { MediaPickerModalType } from '@components/AssetPickers/MediaPickerModal/MediaPickerModal.utils'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'

import LandingPageComposerCustomEditor from '../LandingPageComposerCustomEditor/LandingPageComposerCustomEditor'

import './LandingPageComposerCustomCodeJavaScript.css'

const LandingPageComposerCustomCodeJavaScript = () => {
  const {
    values: {
      message: { templateJson },
      landingPage: { customJavaScript },
    },
    api: { update },
  } = useComposerContext()
  const customCSSFromJson = templateJson?.customCodeFields?.customCss
  const customJavascriptFromJson = templateJson?.customCodeFields?.customJavascript
  const isFirstRender = useRef(true)
  const [customJavaScriptCode, setCustomJavaScriptCode] = useState<string>(customJavaScript || '')

  useEffect(() => {
    update({
      landingPage: { customJavaScript: customJavascriptFromJson, customCss: customCSSFromJson },
    })
  }, [customCSSFromJson, customJavaScriptCode, customJavascriptFromJson])

  useEffect(() => {
    if (isFirstRender.current) {
      setCustomJavaScriptCode(customJavaScript || '')
      if (customJavaScript) {
        isFirstRender.current = false
      }
    }
  }, [customJavaScript])

  const onCodeChange = (code: string) => {
    setCustomJavaScriptCode(code)
    update({
      message: { templateJson: { ...templateJson, customCodeFields: { ...templateJson?.customCodeFields, customJavascript: code } } },
    })
  }

  const onSelectButtonClick = () => {
    update({ showMediaPickerModal: true, mediaPickerModalType: MediaPickerModalType.JS })
  }

  return (
    <div>
      <LandingPageComposerCustomEditor
        mode="javascript"
        value={customJavaScriptCode || ''}
        onChange={onCodeChange}
        onSelectButtonClick={onSelectButtonClick}
        title="LandingPageComposer.CustomCode.JavaScript.Title"
        description="LandingPageComposer.CustomCode.JavaScript.Desc"
        editorTitle="LandingPageComposer.CustomCode.JavaScript.Editor.Title"
        buttonText="LandingPageComposer.CustomCode.JavaScript.Button"
        copyText="Copy"
      />
    </div>
  )
}

export default LandingPageComposerCustomCodeJavaScript
