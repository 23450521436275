import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import { useApolloClient } from '@apollo/client'
import {
  DuplicateListingPageItem,
  ListPageAPI,
  ListPageCommonState,
  PageHeaderProps,
  SetStatusToast,
  SidebarProps,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import ListingPageContainer from '@complex/ListingPage/ListingPageContainer'
import { CatalogLink } from '@complex/ListingPage/Utils/ListingPage.constants'
import LandingPagesPickerModal, {
  createTemplateFromLandingPageModalProps,
} from '@components/AssetPickers/LandingPagesPickerModal/LandingPagesPickerModal'
import TemplateCatalogModalContainer from '@components/AssetPickers/TemplateCatalogModal/TemplateCatalogModalContainer'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import MoveToFolderModal from '@components/MoveToFolderModal/MoveToFolderModal'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextWeight } from '@components/Typography/Typography'
import { ItemDto } from '@graphql/types/query-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemType } from '@utils/categorization'
import { rootContext, useTranslation } from '@utils/const/globals'
import { allLandingPageTemplates } from '@utils/filter'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import {
  LANDING_PAGE_BUILDER_LINK,
  LandingPageBuilderMessage,
  isLandingPageBuilderMessage,
  openLandingPageEditorWindow,
  getLandingPagePreviewUrl,
  landingPageTemplateSubTypes,
} from '@utils/landingPages'
import { UpdateState } from '@utils/types'

import CreateLandingPageTemplateModal, {
  CreateLandingPageTemplateMethod,
} from './components/CreateLandingPageTemplateModal/CreateLandingPageTemplateModal'
import { getLandingPageTemplatesListingPageRequests } from './GraphQL/LandingPageTemplatesListingPageRequests.graphQL'
import { renderCustomFilters } from './utils/LandingPageTemplatesListingPageContainer.helpers'
import {
  LandingPageTemplatesCustomTableActions,
  getCustomEmptyListingProps,
  tableProps,
} from './utils/LandingPageTemplatesListingPageContainer.tables'
import CreateLandingPageModal from '../LandingPages/components/CreateLandingPageModal/CreateLandingPageModal'
import { LandingPages } from '../LandingPages/LandingPagesListingPageContainer.contants'

import './LandingPageTemplatesListingPageContainer.css'

interface LandingPageTemplatesListingPageContainerProps {
  className?: string
  dataTest?: string
}

interface LandingPageTemplatesListingPageContainerState {
  windowMessages: LandingPageBuilderMessage[]
}

type ActionsWithModals = Exclude<LandingPageTemplatesCustomTableActions, LandingPageTemplatesCustomTableActions.EDIT>

type CustomModalType = {
  [key in ActionsWithModals]: () => ReactNode
}

const rootClass = 'landing-page-templates-listing-page-container'

const LandingPageTemplatesListingPageContainer: FC<LandingPageTemplatesListingPageContainerProps> = (
  props: LandingPageTemplatesListingPageContainerProps
) => {
  const { dataTest = rootClass } = props
  const [containerValues, setContainerValues] = useState<LandingPageTemplatesListingPageContainerState>({
    windowMessages: [],
  })
  const updateContainer: UpdateState<LandingPageTemplatesListingPageContainerState> = (fields) => {
    setContainerValues((containerValues) => ({ ...containerValues, ...fields }))
  }

  const { windowMessages } = containerValues
  const actonClient = useApolloClient()
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const {
    getItemRequest,
    deleteLandingPageTemplatesRequest,
    createLandingPageFromTemplateRequest,
    duplicateLandingPageTemplateRequest,
    createTemplateFromLandingPageRequest,
    getLandingPagesRequest,
  } = getLandingPageTemplatesListingPageRequests(actonClient, categorizationClient)

  const accountSettings = useAccountSettings()
  const { userAllowedToCreateContent, userAllowedToDeleteContent, newLPComposerTemplates, newLPComposerBeeCatalog } = accountSettings

  const { t } = useTranslation()
  const [isLandingPagesExist, setIsLandingPagesExist] = useState<boolean>(false)
  const [selectedPageId, setSelectedPageId] = useState<string>('')
  const [showTemplateCatalog, setShowTemplateCatalog] = useState(false)

  useEffect(() => {
    getLandingPagesRequest().then((res) => {
      if (!!res.data?.getAllItems?.length) {
        setIsLandingPagesExist(true)
      }
    })
  }, [])

  const deleteLandingPageTemplates = async (items: ItemDto[]) =>
    deleteLandingPageTemplatesRequest({ pageIds: items.map((item) => item.externalId ?? '').filter((id) => !!id) })

  const duplicateLandingPageTemplate = async (params: DuplicateListingPageItem, listPageAPI: ListPageAPI) => {
    const { listingPageItem, newName, tags, folderId } = params
    const { update, setStatusToast, setError } = listPageAPI

    const { data, errors } = await duplicateLandingPageTemplateRequest({
      pageTemplateId: listingPageItem.externalId ?? '-1',
      newName,
      tags,
      folderId,
    })

    if (data) {
      setStatusToast(t('ListPage.LandingPageTemplates.Duplicate.SuccessToast'), Status.SUCCESS)
      update({ loading: true, fetchItems: true })
    } else {
      setError(t('ListPage.LandingPageTemplates.Duplicate.FailToast'), errors)
    }
  }

  const getLandingPageTemplatePreview = async (listPageValues: ListPageCommonState, update: Update) => {
    const { selectedRows } = listPageValues
    const templateId = selectedRows[0]?.externalId ?? ''
    update({ previewUrl: getLandingPagePreviewUrl(templateId, true) })
  }

  const onWindowMessage = useCallback(
    async (event: MessageEvent<LandingPageBuilderMessage>) => {
      if (isLandingPageBuilderMessage(event.data)) {
        const eventExists = windowMessages.filter((message) => message.lpId === event.data.lpId).length
        !eventExists && updateContainer({ windowMessages: [...windowMessages, { ...event.data }] })
      }
    },
    [windowMessages]
  )

  const onWindowActive = useCallback(
    async (_event: Event, update: Update, setStatusToast: SetStatusToast, items: ItemDto[]) => {
      if (!windowMessages.length) {
        return
      }

      const landingPageTemplateData: ItemDto[] & { item: string }[] = []
      for (const message of windowMessages) {
        const data = await getItemRequest({ externalId: message.lpId, type: ItemType.LANDING_PAGE_TEMPLATE })
        data.data?.getItem && landingPageTemplateData.push(data.data?.getItem)
      }

      if (landingPageTemplateData.length && windowMessages.length) {
        const landingPageTemplateTitle = JSON.parse(landingPageTemplateData[0].item).name
        const isCreated = !items.find((itm) => itm.externalId === windowMessages[0].lpId)
        const message = (
          <Typography
            text={isCreated ? 'ListPage.LandingPageTemplates.LandingPageTemplateCreated' : 'ListPage.LandingPageTemplates.LandingPageTemplateEdited'}
            tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
            values={{ landingPageTemplateTitle }}
            inline
          />
        )

        setStatusToast(message, Status.SUCCESS)
        update({ fetchFolders: true, fetchFilterCounts: true, fetchItems: true })
      }

      updateContainer({ windowMessages: [] })
    },
    [windowMessages]
  )

  const renderCustomModal = (customTableAction: ActionsWithModals, listPageValues: ListPageCommonState, listPageAPI: ListPageAPI) => {
    const { update, setError } = listPageAPI
    const { selectedRows, secondaryFolders } = listPageValues

    const selectedRow = selectedRows[0]

    const row = selectedRow as LandingPages
    const selectedRowId = row?.externalId ?? '-1'
    const closeModal = () => update({ customTableAction: undefined, showCustomModal: false, isProcessingAction: true })

    const customModal: CustomModalType = {
      [LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE]: () => {
        const onSelectLandingPageTemplateCreateMethod = (method: CreateLandingPageTemplateMethod) => {
          if (method === CreateLandingPageTemplateMethod.NEW) {
            window.open(`${LANDING_PAGE_BUILDER_LINK}/blank?template=1`)
            closeModal()
          } else if (method === CreateLandingPageTemplateMethod.LANDING_PAGE) {
            update({ customTableAction: LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE })
          } else {
            closeModal()
          }
        }
        return (
          <CreateLandingPageTemplateModal
            isOpen
            disableLandingPageOption={!isLandingPagesExist}
            onCreate={(method) => onSelectLandingPageTemplateCreateMethod(method)}
            onCancel={closeModal}
            failToastError={{
              title: t('CreateLandingPageTemplateModal.Error.Title.create'),
              message: t('CreateLandingPageTemplateModal.Error.Message.create'),
            }}
            update={update}
          />
        )
      },
      [LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE]: () => {
        return (
          <CreateLandingPageTemplateModal
            isOpen
            showAdditionalOptions
            startId={selectedPageId}
            isLandingListingPage
            duplicateModalProps={{
              onDuplicateCancel: closeModal,
              headerTitleKey: t('Save as template'),
            }}
            onCancel={closeModal}
            includeUnpublishedChangesLabel={t('LandingPage.IncludeUnpublish.Changes.toggle.template')}
            update={update}
            failToastError={{
              title: t('CreateLandingPageTemplateModal.Error.Title.create'),
              message: t('CreateLandingPageTemplateModal.Error.Message.create'),
            }}
          />
        )
      },
      [LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE]: () => {
        const onSelectLandingPage = async (items: (ItemDto & { beeComposer: boolean })[]) => {
          const isBeeComposer = items[0]?.beeComposer

          if (isBeeComposer && newLPComposerTemplates) {
            update({ customTableAction: LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE_FROM_LANDING_PAGE_DUPLICATE })
          } else {
            const { data, errors } = await createTemplateFromLandingPageRequest({ landingPageId: items[0].externalId })
            if (data && data.createTemplateFromLandingPage.id) {
              openLandingPageEditorWindow(data.createTemplateFromLandingPage.id)
            }
            if (errors) {
              setError(t('There was a problem creating your template'), errors)
            }
            closeModal()
          }
        }

        return (
          <LandingPagesPickerModal
            {...createTemplateFromLandingPageModalProps}
            disableRowByCriteria={(row) => !newLPComposerTemplates && row.original.beeComposer}
            onSubmit={(items) => {
              onSelectLandingPage(items as (ItemDto & { beeComposer: boolean })[])
              setSelectedPageId(items[0]?.externalId ?? '')
            }}
            onClose={() => update({ customTableAction: LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE })}
          />
        )
      },
      [LandingPageTemplatesCustomTableActions.CREATE_LANDING_PAGE]: () => {
        const onCreateLandingPage = async (folderId: number) => {
          const { data, errors } = await createLandingPageFromTemplateRequest({ folderId, id: selectedRowId, title: row?.name })
          if (data && data.copyLandingPageOrTemplate) {
            openLandingPageEditorWindow(data.copyLandingPageOrTemplate)
            window.location.assign(`${rootContext}/content/landingPages`)
          }
          if (errors) {
            setError(t('There was a problem creating your landing page'), errors)
          }
          closeModal()
        }

        return (
          <MoveToFolderModal
            folders={secondaryFolders as FolderData[]}
            addToFolder={true}
            headerText={t('Create landing page')}
            description={t('Save to folder')}
            primaryButtonText={t('Create')}
            folderPrimaryButtonText={t('Select')}
            onMoveClick={onCreateLandingPage}
            onClose={closeModal}
            isOpen
          />
        )
      },
      [LandingPageTemplatesCustomTableActions.CREATE_LANDING_PAGE_FROM_TEMPLATE]: () => {
        return (
          <CreateLandingPageModal
            isOpen
            isLandingListingPage
            showAdditionalOptions
            startId={selectedRowId}
            duplicateModalProps={{
              onDuplicateCancel: closeModal,
            }}
            onCancel={closeModal}
            update={update}
            failToastError={{
              title: t('CreateLandingPageModal.Error.Title'),
              message: t('CreateLandingPageModal.Error.Message'),
            }}
          />
        )
      },
    }

    if (!(customTableAction in customModal)) {
      return
    }
    return customModal[customTableAction]()
  }

  const renderCreateLandingPageTemplateButton = (update: Update) => (
    <Button
      buttonType={ButtonType.PRIMARY}
      iconPosition={ButtonIconPosition.LEFT}
      disabled={!userAllowedToCreateContent}
      onClick={() => update({ showCustomModal: true, customTableAction: LandingPageTemplatesCustomTableActions.CREATE_TEMPLATE })}
      className={classNames(`${rootClass}__create-template-button`, {
        [`${rootClass}__create-template-button-disabled`]: !userAllowedToCreateContent,
      })}
      dataTest={`${rootClass}-create-template-button`}
    >
      <Svg type={SvgType.ICON} name={SvgNames.plus} />
      {t('Create template')}
    </Button>
  )

  const templateCatalogButton = (
    <Button
      buttonType={ButtonType.OUTLINE}
      iconPosition={ButtonIconPosition.LEFT}
      dataTest={`${rootClass}-template-catalog`}
      onClick={() => setShowTemplateCatalog(true)}
      className={`${rootClass}__template-catalog-button`}
    >
      <Svg name={SvgNames.shareToCatalog} type={SvgType.ICON} />
      {t('Template.Catalog')}
    </Button>
  )

  const pageHeaderProps: PageHeaderProps = {
    pageTitle: 'Landing Pages',
    renderPageHeaderContent: (update) => (
      <div className={`${rootClass}__template-buttons-wrapper`}>
        {newLPComposerBeeCatalog ? (
          templateCatalogButton
        ) : (
          <Button
            className={classNames(`${rootClass}__content-catalog`, `listing-page-header__catalog-button`)}
            buttonType={ButtonType.FLOAT}
            isLink
            to={CatalogLink.LANDING_PAGE}
            target={'_blank'}
          >
            <Svg type={SvgType.ICON} name={SvgNames.shareToCatalog} />
            <Typography lineHeight={LineHeight.SMALL} text={t('Content catalog')} className={'listing-page-header__catalog-text'} />
          </Button>
        )}
        {userAllowedToCreateContent ? (
          renderCreateLandingPageTemplateButton(update)
        ) : (
          <Tooltip trigger={renderCreateLandingPageTemplateButton(update)}>{t('Ask your administrator for permission to do this')}</Tooltip>
        )}
      </div>
    ),
  }

  const sidebarProps: SidebarProps = {
    sidebarHeader: 'Templates',
    hasRecent: false,
    hasCreatedByMe: true,
    allItemFilter: allLandingPageTemplates,
    renderCustomFilters: (params) => renderCustomFilters(params),
    customFilterSelected: false,
  }

  const goToCatalog = () => window.open(CatalogLink.LANDING_PAGE, '_blank')

  return (
    <>
      {showTemplateCatalog && <TemplateCatalogModalContainer setShowTemplateCatalog={setShowTemplateCatalog} />}
      <ListingPageContainer
        dataTest={dataTest}
        className={classNames(rootClass)}
        itemType={ItemType.LANDING_PAGE_TEMPLATE}
        listingPageProps={{
          canPreview: true,
          hasTabs: true,
          subTypes: landingPageTemplateSubTypes,
          canDuplicate: true,
          canDeleteItems: userAllowedToDeleteContent,
          canEdit: userAllowedToCreateContent,
          canCreate: userAllowedToCreateContent,
          isDeleteCallWithBulkResponse: true,
          sidebarProps,
          pageHeaderProps,
          hasSecondaryFolders: true,
          secondaryItemType: ItemType.LANDING_PAGE,
          tableProps: tableProps(t, userAllowedToCreateContent, newLPComposerTemplates),
          getCustomEmptyListingProps: (setFilter, update, filter) => getCustomEmptyListingProps(setFilter, update, goToCatalog, filter),
          renderCustomModal,
          customDeleteItemsCall: deleteLandingPageTemplates,
          customDuplicateItem: duplicateLandingPageTemplate,
          customPreviewItemCall: getLandingPageTemplatePreview,
          onWindowMessage,
          onWindowActive,
          sortBy: [{ id: 'lastUpdated', desc: true }],
        }}
      />
    </>
  )
}

export default LandingPageTemplatesListingPageContainer
