import React, { FC, useContext, useMemo, useState } from 'react'
import { ConnectDragSource } from 'react-dnd'

import classNames from 'classnames'

import { ButtonIconPosition, ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox'
import { YesNo } from '@components/ConfirmationModal'
import DeleteConfirmationModal from '@components/DeleteConfirmationModal/DeleteConfirmationModal'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import Svg, { SvgColor, SvgNames, SvgType } from '@components/Svg'
import TriggerButton from '@components/TriggerButton/TriggerButton'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { GroupDefinitionDto, RowDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import BehaviorExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression'
import CRMExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/CRMExpression/CRMExpression'
import CustomDataExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/CustomDataExpression/CustomDataExpression'
import ProfileExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ProfileExpression/ProfileExpression'
import ConditionGroup from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/Row/components/ConditionGroup/ConditionGroup'
import ScoreExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/ScoreExpression/ScoreExpression'
import { getCoordinates } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentDefinition/SegmentDefinition.utils'
import SegmentMembershipExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SegmentMembershipExpression/SegmentMembershipExpression'
import SMSExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SMSExpression/SMSExpression'
import SubscriptionExpression from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SubscriptionExpression/SubscriptionExpression'
import { SystemExpression } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/SystemExpression/__stories__/SystemExpression.stories'
import {
  Criteria,
  CRITERIA_WITH_LINKED_ROWS,
  criteriaByFactorType,
  dropdownFooter,
  factorTypeByCriteria,
  getCriteriaOptions,
  iconByCriteria,
  RowAction,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/utils/SegmentComposerBuild.utils'
import { useSegmentDefinitionActions } from '@src/pages/SegmentComposer/hooks/useSegmentDefinitionActions'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'

import './Row.css'

export interface RowProps {
  className?: string
  dataTest?: string
  isDragged?: boolean
  row: RowDefinitionDto
  onSelect: (selected: boolean) => void
  dragSource?: ConnectDragSource
  isRecentlyDropped?: boolean
}

const rootClass = 'segment-composer-row'

const Row: FC<RowProps> = (props: RowProps) => {
  const { dataTest = rootClass, className = '', isDragged, row, onSelect, dragSource, isRecentlyDropped } = props
  const { factor, rowId } = row
  const { type } = factor || {}

  const { t } = useTranslation()

  const {
    values: { segmentDefinition },
    update,
  } = useContext(SegmentComposerContext)

  const { duplicateRow, turnRowIntoGroup } = useSegmentDefinitionActions()

  const { group } = segmentDefinition

  const canTurnIntoGroup = useMemo(() => {
    const coordinates = getCoordinates(row.rowId, group as GroupDefinitionDto)
    return !!coordinates && coordinates.length <= 2
  }, [row.rowId, group])

  const criteriaOptions = getCriteriaOptions(t)
  const criteriaValue = type ? criteriaByFactorType[type] : Criteria.PROFILE

  // TODO: subRows not supported on the backend yet
  const [subRows, setSubRows] = useState<(RowDefinitionDto & { parentId: string })[]>([]) //row.subRows

  const hasSubRows = !!subRows && subRows.length > 0
  const defaultCriteria = criteriaOptions.find((criteria) => criteria.value === criteriaValue) || criteriaOptions[0]

  const [selectedCriteria, setSelectedCriteria] = useState<SelectV2SingleOption>(defaultCriteria)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const onAddLinkedRow = () => {
    // TODO: using temporal state since it is not supported on the backend yet
    setSubRows([...subRows, { parentId: rowId, ...row }])
    const updatedRow = {
      ...row,
      // subRows: [...(row.subRows || []), { parentId: rowId, ...defaultExpression }],
    } as RowDefinitionDto

    update({
      segmentDefinition: {
        ...segmentDefinition,
        group: editRow(updatedRow),
      },
    })
  }

  const onActionClick = (action: RowAction) => {
    const rowActionHandlers = {
      [RowAction.ADD]: onAddLinkedRow,
      [RowAction.DUPLICATE]: () => duplicateRow(row, segmentDefinition),
      [RowAction.DELETE]: () => setDeleteOpen(true),
      [RowAction.GROUP]: () => turnRowIntoGroup(row, segmentDefinition),
    }
    rowActionHandlers[action]()
  }

  const onDefinitionChange = (updatedRow: Partial<RowDefinitionDto>) => {
    const updatedRowDefinition: RowDefinitionDto = { ...row, ...updatedRow }
    update({
      segmentDefinition: {
        ...segmentDefinition,
        group: editRow(updatedRowDefinition),
      },
    })
  }

  const actionsOptions = [
    {
      text: t('SegmentComposer.Build.Row.Actions.Add'),
      icon: SvgNames.addLinkedRow,
      onClick: () => onActionClick(RowAction.ADD),
      hidden: !CRITERIA_WITH_LINKED_ROWS.includes(selectedCriteria.value as Criteria),
    },
    {
      text: t('SegmentComposer.Build.Row.Actions.Duplicate'),
      icon: SvgNames.copyStep,
      onClick: () => onActionClick(RowAction.DUPLICATE),
    },
    {
      text: t('SegmentComposer.Build.Row.Actions.Group'),
      icon: SvgNames.turnIntoGroup,
      onClick: () => onActionClick(RowAction.GROUP),
      hidden: !canTurnIntoGroup,
    },
    {
      text: t('SegmentComposer.Build.Row.Actions.Delete'),
      icon: SvgNames.delete,
      onClick: () => onActionClick(RowAction.DELETE),
    },
  ]

  const renderRowDefinition = (criteria: Criteria) => {
    const criteriaDefinition = {
      [Criteria.PROFILE]: <ProfileExpression row={row} />,
      [Criteria.BEHAVIOR]: <BehaviorExpression />,
      [Criteria.SCORE]: <ScoreExpression />,
      [Criteria.SMS]: <SMSExpression />,
      [Criteria.SUBSCRIPTION]: <SubscriptionExpression />,
      [Criteria.SEGMENT]: <SegmentMembershipExpression />,
      [Criteria.SYSTEM]: <SystemExpression />,
      [Criteria.CRM]: <CRMExpression row={row} onChange={onDefinitionChange} />,
      [Criteria.CUSTOM_DATA]: <CustomDataExpression />,
    }
    return criteriaDefinition[criteria]
  }

  const getRowDefinitionText = () => {
    // TODO: get the actual row definition text for each row type
    return 'Row definition text Mock'
  }

  /**
   * Updates a specific row within the root group or any nested group with new values and
   * returns the updated root expression group.
   *
   * @param {Partial<RowDefinitionDto>} newValues - An object containing the properties to be updated
   *                                             for the target row. Only properties provided in
   *                                             this object will be updated.
   * @returns {GroupDefinitionDto} - The updated root expression group after applying the changes.
   */
  const editRow = (newValues: Partial<RowDefinitionDto>): GroupDefinitionDto => {
    const updatedRoot = { ...group } as GroupDefinitionDto

    const editRowRecursively = (group: GroupDefinitionDto) => {
      if (group.rows && group.rows.length > 0) {
        const rowIndex = group.rows.findIndex((row) => row?.rowId === rowId)
        if (rowIndex !== -1) {
          group.rows[rowIndex] = { ...group.rows[rowIndex], ...newValues } as RowDefinitionDto
          return
        }
      }

      if (group.subGroups && group.subGroups.length > 0) {
        group.subGroups.forEach((subGroup) => {
          if (subGroup) {
            editRowRecursively(subGroup)
          }
        })
      }
    }

    editRowRecursively(updatedRoot)
    return updatedRoot
  }

  const onCriteriaChange = (option: SelectV2SingleOption) => {
    if (option.value !== selectedCriteria.value) {
      // TODO: get default Factor by Criteria, each time the criteria change we need to update the factor
      const updatedRow = {
        ...row,
        factor: {
          ...factor,
          type: factorTypeByCriteria[option.value as Criteria],
        },
      } as RowDefinitionDto

      update({
        segmentDefinition: {
          ...segmentDefinition,
          group: editRow(updatedRow),
        },
      })
    }
    setSelectedCriteria(option as SelectV2SingleOption)
  }

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__draggable`]: dragSource,
        [`${rootClass}__dragged`]: isDragged,
        [`${rootClass}__dropped`]: isRecentlyDropped,
      })}
      data-test={`${dataTest}-${rowId}`}
    >
      <DeleteConfirmationModal
        title={t('Are you sure?')}
        body={
          <Typography
            text={t('SegmentComposer.Build.Row.DeleteModal.Text', {
              rowDefinition: getRowDefinitionText(),
            })}
            tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
          />
        }
        deleteButtonText={t('Delete')}
        isOpen={deleteOpen}
        onAnswer={(answer: YesNo) => {
          if (answer === YesNo.YES) {
            // TODO: do the delete action
          }
          setDeleteOpen(false)
        }}
      />
      <div className={`${rootClass}__left-actions`}>
        <div className={classNames(`${rootClass}__left-actions-drag`)} data-test={`${dataTest}-draggable-section`} ref={dragSource}>
          <Svg name={SvgNames.drag} type={SvgType.ICON} fill={SvgColor.LIGHT_GRAY} />
        </div>
        <Checkbox dataTest={`${dataTest}-checkbox`} onChange={onSelect} />
      </div>
      <div className={`${rootClass}__row-wrapper`}>
        <div className={`${rootClass}__criteria-wrapper`}>
          <div className={`${rootClass}__criteria-select-container`}>
            <SelectV2
              dataTest={`${dataTest}-criteria-select`}
              className={`${rootClass}__criteria-select`}
              options={criteriaOptions}
              defaultValue={selectedCriteria}
              onChange={(option) => onCriteriaChange(option as SelectV2SingleOption)}
              isClearable={false}
              showIconOnSelect
              renderSingleValue={() => ''}
              inputIcon={iconByCriteria[selectedCriteria?.value as Criteria]}
              isSearchable={false}
            />
            {hasSubRows && <div className={`${rootClass}__criteria-select-depth-indicator`} />}
          </div>
          <div
            className={classNames(`${rootClass}__row-definition`, {
              [`${rootClass}__row-definition-with-padding`]: hasSubRows,
            })}
          >
            {renderRowDefinition(selectedCriteria?.value as Criteria)}
          </div>
          <TriggerButton
            dataTest={`${dataTest}-action-button`}
            className={`${rootClass}__action-button`}
            dropDownProps={{ align: 'end', footer: dropdownFooter }}
            alignRight={false}
            label={''}
            useLabel={false}
            options={actionsOptions}
            buttonType={ButtonType.FLOAT}
            svgName={SvgNames.overflowMenu}
            svgIcon={SvgType.LARGER_ICON}
            showCaretIcon={false}
            iconPosition={ButtonIconPosition.FLOAT}
          />
        </div>
        {hasSubRows && <ConditionGroup row={row}>{subRows.map(() => renderRowDefinition(selectedCriteria?.value as Criteria))}</ConditionGroup>}
      </div>
    </div>
  )
}

export default Row
