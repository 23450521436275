import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { CrmFactor, RowDefinitionDto } from '@graphql/types/microservice/segment-definition-types'
import { CrmEntityUiMetaData, CrmFieldMetaData } from '@graphql/types/query-types'
import BooleanCriteria, {
  BooleanCriteriaType,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BooleanCriteria/BooleanCriteria'
import DateCriteria, { DateCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/DateCriteria/DateCriteria'
import NumericCriteria, {
  NumericCriteriaType,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/NumericCriteria/NumericCriteria'
import TextCriteria, { TextCriteriaType } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/TextCriteria/TextCriteria'
import { FactorType } from '@src/pages/SegmentComposer/SegmentComposer.constants'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'

import './CRMExpression.css'

type CrmEntityOption = SelectV2SingleOption<CrmEntityUiMetaData>
type CrmFieldOption = SelectV2SingleOption<CrmFieldMetaData>

interface CRMExpressionProps {
  className?: string
  dataTest?: string
  row: RowDefinitionDto
  onChange: (row: Partial<RowDefinitionDto>) => void
}

const rootClass = 'crm-expression'

const CRMExpression: FC<CRMExpressionProps> = (props: CRMExpressionProps) => {
  const { dataTest = rootClass, className = '', row, onChange } = props

  const {
    values: { crmEntityOptions },
  } = useContext(SegmentComposerContext)

  // TODO these states will be removed once we store these values in the row definition
  const [textCriteria, setTextCriteria] = useState<TextCriteriaType>({ criteria: 'EQUALS', values: [] })
  const [numericCriteria, setNumericCriteria] = useState<NumericCriteriaType>({ criteria: 'EQUALS' })
  const [dateCriteria, setDateCriteria] = useState<DateCriteriaType>({ criteria: 'IS_EQUAL' })
  const [booleanCriteria, setBooleanCriteria] = useState<BooleanCriteriaType>({ criteria: 'IS_EQUAL', value: false })
  const [selectedPickListValues, setSelectedPickListValues] = useState<Record<string, SelectV2SingleOption[] | undefined>>({})

  const selectedCrmEntity = useMemo(
    () => crmEntityOptions?.find(({ value }) => value === row.factor?.crmFactor?.entityType) ?? crmEntityOptions?.[0],
    [crmEntityOptions, row.factor]
  )

  const crmFieldOptions = useMemo(
    () =>
      selectedCrmEntity?.extraOptions?.entityFields?.map<CrmFieldOption>((field) => ({
        label: field?.displayName ?? '',
        value: field?.fieldName ?? '',
        extraOptions: field,
      })),
    [selectedCrmEntity]
  )

  const selectedCrmField = useMemo(
    () => crmFieldOptions?.find(({ value }) => value === row.factor?.crmFactor?.field) ?? crmFieldOptions?.[0],
    [crmFieldOptions, row.factor]
  )

  const getPickListValueOptions = (crmFieldOption?: CrmFieldOption) => {
    return crmFieldOption?.extraOptions?.pickListValues?.map((value = '') => ({ label: value, value }))
  }

  const onPickListValueChange = (option: SelectV2SingleOption[] | SelectV2SingleOption | undefined) => {
    if (selectedCrmField) {
      const key = `${selectedCrmEntity?.value}:${selectedCrmField.value}`
      setSelectedPickListValues((selectedPickListValues) => ({
        ...selectedPickListValues,
        [key]: option ? (Array.isArray(option) ? option : [option]) : undefined,
      }))
    }
  }

  const handleChange = (crmFactorField: keyof CrmFactor, option?: CrmEntityOption | CrmFieldOption) => {
    if (option) {
      const isCrmEntityOption = (option: CrmEntityOption | CrmFieldOption): option is CrmEntityOption =>
        crmFactorField === 'entityType' && !!option?.extraOptions && 'entityFields' in option.extraOptions

      onChange({
        ...row,
        factor: {
          type: FactorType.Crm,
          crmFactor: {
            ...row.factor?.crmFactor,
            [crmFactorField]: option.value,
            ...(isCrmEntityOption(option) && {
              field: option.extraOptions?.entityFields?.[0]?.fieldName,
            }),
          },
        },
      })
    }
  }

  const onEntityChange = (option?: CrmEntityOption) => handleChange('entityType', option)

  const onFieldChange = (option?: CrmFieldOption) => handleChange('field', option)

  const onTextCriteriaCreate = (inputValue: string) => {
    if (!textCriteria.values.some((option) => option.label === inputValue)) {
      setTextCriteria({ ...textCriteria, values: [...textCriteria.values, { label: inputValue, value: inputValue }] })
    }
  }

  const onTextCriteriaChange = (criteria: TextCriteriaType) => {
    setTextCriteria(criteria)
  }

  const onNumericCriteriaChange = (criteria: NumericCriteriaType) => {
    setNumericCriteria(criteria)
  }

  const onDateCriteriaChange = (criteria: DateCriteriaType) => {
    setDateCriteria(criteria)
  }

  const onBooleanCriteriaChange = (criteria: BooleanCriteriaType) => {
    setBooleanCriteria(criteria)
  }

  useEffect(() => {
    if (!row.factor?.crmFactor?.entityType && selectedCrmEntity) {
      // if the row does not have an entity selected, we need to set the default value
      onChange({
        ...row,
        factor: {
          type: FactorType.Crm,
          crmFactor: {
            entityType: selectedCrmEntity.value,
            field: selectedCrmEntity.extraOptions?.entityFields?.[0]?.fieldName,
          },
        },
      })
    }
  }, [onChange, row, row.factor, selectedCrmEntity])

  const renderNextCondition = () => {
    if (selectedCrmEntity && selectedCrmField?.extraOptions?.dataType) {
      const entityName = selectedCrmEntity.value
      const { fieldName = '', dataType } = selectedCrmField.extraOptions
      switch (dataType) {
        case 'CURRENCY':
        case 'INTEGER':
        case 'NUMERIC':
        case 'PERCENT':
          return <NumericCriteria dataTest={`${dataTest}-numeric-criteria`} onChange={onNumericCriteriaChange} numericCriteria={numericCriteria} />
        case 'DATE':
        case 'DATETIME':
          return <DateCriteria dataTest={`${dataTest}-date-criteria`} onChange={onDateCriteriaChange} dateCriteria={dateCriteria} />
        case 'BOOLEAN':
          return <BooleanCriteria dataTest={`${dataTest}-boolean-criteria`} onChange={onBooleanCriteriaChange} booleanCriteria={booleanCriteria} />
        case 'MUILTIPICKLIST':
        case 'PICKLIST':
          const isMultiple = dataType === 'MUILTIPICKLIST'
          const options = getPickListValueOptions(selectedCrmField)
          return (
            <SelectV2
              {...(isMultiple ? { onChangeMultiple: onPickListValueChange } : { onChange: onPickListValueChange })}
              key={`picklist-${fieldName}`}
              className={`${rootClass}__select`}
              dataTest={`${dataTest}-picklist-criteria`}
              options={options}
              value={selectedPickListValues[`${entityName}:${fieldName}`]}
              truncateMultiValues
              minSearchOptions={1}
            />
          )
        case 'REFERENCE':
          // TODO: add support for reference fields
          return null
        default:
          return (
            <TextCriteria
              dataTest={`${dataTest}-text-criteria`}
              onChange={onTextCriteriaChange}
              onCreate={onTextCriteriaCreate}
              textCriteria={textCriteria}
            />
          )
      }
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <SelectV2
        className={`${rootClass}__select`}
        dataTest={`${dataTest}-entity-select`}
        options={crmEntityOptions}
        onChange={onEntityChange}
        value={selectedCrmEntity}
        isClearable={false}
        isSearchable={false}
      />
      {selectedCrmEntity && (
        <SelectV2
          className={`${rootClass}__select`}
          dataTest={`${dataTest}-field-select`}
          options={crmFieldOptions}
          onChange={onFieldChange}
          value={selectedCrmField}
          isClearable={false}
          isSearchable={false}
        />
      )}
      {renderNextCondition()}
    </div>
  )
}

export default CRMExpression
