import Cookies from 'js-cookie'

export enum LoginCookie {
  MFA = 'ao_mfa',
  CUSTOMER_CARE = 'ccl',
  USER = 'uc',
}

export function listenCookieChange(callback: () => void, interval = 1000) {
  let lastCookie = document.cookie
  setInterval(() => {
    const cookie = document.cookie
    if (cookie !== lastCookie) {
      try {
        callback()
      } finally {
        lastCookie = cookie
      }
    }
  }, interval)
}

export const isCustomerCareLogin = () => !!Cookies.get(LoginCookie.CUSTOMER_CARE)

export default {
  listenCookieChange,
}
